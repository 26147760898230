import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Alert,
  Card,
  Form,
  Spinner,
  Modal,
  Button,
  ListGroup
} from 'react-bootstrap';
import ListPaginator from 'components/Paginators/ListPaginator';
import CropImage from 'components/Images/CropImageSVG';
import {
  Link,
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  getAuditReportBBS,
  getAuditReportLabel,
  getAuditReportMistakeTypes,
  updateAuditReport
} from 'api/auditReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBottleWater } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'react-select';
import Select from '../../components/Select/Select';
import { useUserContext } from '../../contexts/Users';
import { getCrop } from '../../api/featurestore';
import ModalWithSVGShape from '../../components/Modal/ModalWithSVGShape';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import { HttpStatus } from '../../enums';
import SingleSKUReferenceItem from '../CropLabelingProcess/SingleSKUReferenceItem';
import {
  fetchImagesQueued,
  fetchQueued
} from '../../services/fetchImagesQueued';
import ProductionReportChecksFilters from './ProductionReportChecksFilters';

function ReportCheckRow({
  crop,
  showModal,
  onClickBBS,
  handleChangeState,
  mistakesList,
  onSendMistakes,
  isLoading,
  isViewer
}) {
  const mistakesRadioGroup = ['WRONG_BOTH', 'WRONG_TAGGED', 'WRONG_PREDICTION'];

  return (
    <>
      <div className={crop.flag_auto_checked && 'auto_checked'}>
        <Row>
          <Col xl="3" lg="4" md="4" sm="5" xs="12">
            <div className="d-flex justify-content-center align-items-center">
              {!crop.loadingImage ? (
                <>
                  {!crop.isLoadingImageError && (
                    <div
                      onClick={() => showModal(crop)}
                      style={{ cursor: 'pointer' }}
                    >
                      <CropImage
                        width="200px"
                        height="200px"
                        disabled
                        source={crop.preReviewCrop?.source.crop}
                        rectangle={crop.preReviewCrop?.source.rect}
                      />
                    </div>
                  )}
                  {crop.isLoadingImageError && (
                    <div
                      style={{ width: '200px', height: '200px' }}
                      className="card d-flex justify-content-center align-items-center mb-0"
                    >
                      <FontAwesomeIcon
                        style={{ color: 'lightgray', height: '75%' }}
                        icon={faBottleWater}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Spinner size="lg" animation="border" role="status" />
              )}
            </div>
          </Col>
          <Col xl="9" lg="8" md="8" sm="7" xs="12">
            <Row>
              <Col xl="6" sm="12">
                <p className="card-category">
                  <span
                    onClick={() => onClickBBS(crop.tagged)}
                    className="d-inline-block my-1"
                  >
                    <a
                      className="page-link"
                      href="#"
                      title={`Brand Bank search for: ${crop.tagged}`}
                      style={{ padding: '3px 8px' }}
                    >
                      BBS
                    </a>
                  </span>{' '}
                  Tagged: {crop.tagged}
                </p>
                <p className="card-category">
                  <span
                    onClick={() => onClickBBS(crop.prediction)}
                    className="d-inline-block my-1"
                  >
                    <a
                      className="page-link"
                      href="#"
                      title={`Brand Bank search for: ${crop.prediction}`}
                      style={{ padding: '3px 8px' }}
                    >
                      BBS
                    </a>
                  </span>{' '}
                  Prediction: {crop.prediction}
                </p>
                <p className="card-category">Score: {crop.score}</p>
                <p className="card-category">FS label id: {crop.fs_label_id}</p>
                <p className="card-category">Photo id: {crop.photo_id}</p>
                <p className="card-category">
                  Created crop job id: {crop.created_crop_job_ids.join(', ')}
                </p>
                <p className="card-category">
                  Labeler(s): {crop.labelers.join(', ')}
                </p>
                <p className="card-category mb-2">Rewiever: {crop.reviewer}</p>
              </Col>
              <Col xl="6" sm="12">
                <div className="mt-1 ml-n1">
                  {!isViewer && crop.isLoadingMistake && (
                    <Spinner size="lg" animation="border" role="status" />
                  )}
                  {!isViewer && !crop.isLoadingMistake && (
                    <div>
                      {crop.mistakeSuccessMessage && (
                        <Alert variant="success">success</Alert>
                      )}
                      {crop.mistakeErrorMessage && (
                        <Alert variant="danger">error</Alert>
                      )}
                      <Form className="pl-4 mt-n3">
                        <Row>
                          <div className="mr-3 mt-3 pl-3">
                            {mistakesRadioGroup.map((itemRadio) => (
                              <Form.Check.Label
                                className="d-block"
                                key={itemRadio}
                              >
                                <Form.Check.Input
                                  name="radioGroup"
                                  id={itemRadio}
                                  checked={crop.flag === itemRadio}
                                  onChange={(e) =>
                                    handleChangeState(
                                      e.target.value,
                                      'flag',
                                      crop.id
                                    )
                                  }
                                  value={itemRadio}
                                  type="radio"
                                />
                                <span className="text-large">{itemRadio}</span>
                              </Form.Check.Label>
                            ))}
                          </div>
                          <div className="mr-3 mt-3">
                            <Select
                              value={crop.mistakeValue}
                              onChange={(e) =>
                                handleChangeState(e, 'mistakeValue', crop.id)
                              }
                              isDisabled={
                                crop.mistakeIsLoading ||
                                crop.flag === 'WRONG_TAGGED' ||
                                !crop.flag
                              }
                              options={mistakesList}
                              placeholder="Select mistake(s)"
                              closeMenuOnSelect={false}
                              isMulti
                            />
                          </div>
                          <div className="mr-3 mt-3">
                            <SpinnerButton
                              style={{ height: '50px' }}
                              isLoading={isLoading}
                              disabled={crop.isDisabledMistakeButton}
                              onClick={() => onSendMistakes(crop.id)}
                              value="Send"
                            />
                          </div>
                        </Row>
                      </Form>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <hr />
    </>
  );
}

function ProductionReportCheck() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentId, setCurrentId] = useState(null);
  const [count, setCount] = useState(null);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [label, setLabel] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [error, setError] = useState(false);
  const [backLink, setBackLink] = useState({});
  const [listCheckCrops, setListCheckCrops] = useState([]);
  const [showSVGShapeModal, setShowSVGShapeModal] = useState(false);
  const [dimensionsModal, setDimensionsModal] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const [errorTextModal, setErrorTextModal] = useState('');
  const [mistakesList, setMistakesList] = useState([]);
  const [showModalBBS, setShowModalBBS] = useState(false);
  const [resultBBS, setResultBBS] = useState([]);
  const [infoBBS, setInfoBBS] = useState({});
  const [parameters, setParameters] = useState({});
  const cancelLoadRef = useRef(() => {});

  const { id } = useParams();
  const { state } = useLocation();
  const { isViewer } = useUserContext();

  useEffect(() => {
    const params = { for_sku_id: id };
    getAuditReportMistakeTypes(params).then(async (response) => {
      const data = await response.json();
      const newMistakesList = [];
      data.forEach((el) =>
        newMistakesList.push({ label: el.name, value: el.name })
      );
      setMistakesList(newMistakesList);
    });
  }, []);

  useEffect(() => {
    if (state && state.label) {
      setLabel(state.label);
      setBackLink({ pathname: state.pathnameBack, state: state.stateBack });
    }
  }, [state]);

  const fetchPreReviewCrops = async (data) => {
    const apiCall = (crop) => {
      return getCrop(crop.fs_label_id, {
        photo_id: crop.photo_id,
        centroid_scale: 1.25,
        photo_as_url: true
      });
    };

    const onImageLoaded = (response, crop) => {
      setListCheckCrops((prev) => {
        const index = prev.findIndex((el) => el.id === crop.id);
        const nextState = [...prev];
        if (index >= 0) {
          nextState[index].preReviewCrop = {
            source: response,
            photoId: crop.photo_id
          };
          nextState[index].loadingImage = false;
        }
        return nextState;
      });
    };

    const onImageLoadFailed = (crop) => {
      setListCheckCrops((prev) => {
        const index = prev.findIndex((el) => el.id === crop.id);
        const nextState = [...prev];
        if (index >= 0) {
          nextState[index].loadingImage = false;
          nextState[index].isLoadingImageError = true;
        }
        return nextState;
      });
    };

    const getImgUrl = (data) => data.crop;

    const { cancelLoad } = fetchImagesQueued(
      data,
      apiCall,
      onImageLoaded,
      onImageLoadFailed,
      getImgUrl
    );

    cancelLoadRef.current = cancelLoad;
  };

  const setAuditReportCheck = (params) => {
    cancelLoadRef.current();
    cancelLoadRef.current = () => {};

    getAuditReportLabel(id, params).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response.json().then(async (data) => {
          const response = data.results;
          if (!response || response.length === 0) {
            setCount(null);
            setNext(null);
            setPrevious(null);
            setCurrentId(id);
            setError(false);
            setListCheckCrops([]);
            return;
          }
          setCount(data.count);
          setNext(data.next);
          setPrevious(data.previous);
          setCurrentId(id);
          setError(false);

          const newReportDetails = [];
          response.forEach((item) => {
            const newMistakesList = [];
            item.mistake_types.forEach((el) =>
              newMistakesList.push({ label: el, value: el })
            );
            newReportDetails.push({
              ...item,
              mistakeValue: newMistakesList,
              mistakeSuccessMessage: false,
              mistakeErrorMessage: false,
              mistakeIsLoading: false,
              isDisabledMistakeButton: true,
              isLoadingMistake: false,

              isLoadingImageError: false,
              loadingImage: true
            });
          });
          setListCheckCrops(newReportDetails);
          fetchPreReviewCrops(newReportDetails);
        });
      } else {
        setError(true);
        setListCheckCrops([]);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (currentId !== id) {
      setAuditReportCheck({ page: 1, page_size: pageSize, ...parameters });
    }
  }, [id]);

  const handleChangeState = (newValue, key, id) => {
    let newState = {};
    switch (key) {
      case 'flag':
        newState = {
          flag: newValue,
          mistakeValue: [],
          mistakeSuccessMessage: false,
          mistakeErrorMessage: false,
          isDisabledMistakeButton: newValue !== 'WRONG_TAGGED'
        };
        break;
      case 'mistakeValue':
        newState = {
          mistakeValue: newValue,
          mistakeSuccessMessage: false,
          mistakeErrorMessage: false,
          isDisabledMistakeButton: Object.keys(newValue).length === 0
        };
        break;
      case 'mistakeSuccessMessage':
        newState = {
          mistakeSuccessMessage: true,
          mistakeErrorMessage: false,
          isLoadingMistake: false
        };
        break;
      case 'mistakeErrorMessage':
        newState = {
          mistakeSuccessMessage: false,
          mistakeErrorMessage: true,
          isLoadingMistake: false
        };
        break;
      default:
        newState = {
          [key]: newValue
        };
    }

    setListCheckCrops(
      listCheckCrops.map((item) => {
        if (item.id === id) {
          return { ...item, ...newState };
        }
        return item;
      })
    );
  };

  const loadData = ({ page }) => {
    setIsLoading(true);
    setPage(page);
    setAuditReportCheck({ page: page, page_size: pageSize, ...parameters });
  };

  const onSendMistakes = (id) => {
    handleChangeState(true, 'isLoadingMistake', id);
    const crop = listCheckCrops.find((el) => el.id === id);
    const mistakes = crop.mistakeValue.map((el) => el.value);
    const params =
      mistakes.length > 0
        ? {
            flag: crop.flag,
            mistake_types: mistakes.join(',')
          }
        : {
            flag: crop.flag
          };
    updateAuditReport(id, params).then((res) => {
      if (res.status === HttpStatus.SUCCESS) {
        handleChangeState(true, 'mistakeSuccessMessage', id);
      } else {
        handleChangeState(true, 'mistakeErrorMessage', id);
      }
    });
  };

  const onSendAllMistakes = async () => {
    setListCheckCrops(
      listCheckCrops.map((item) => {
        return { ...item, isLoadingMistake: true };
      })
    );

    const sendErrorCrop = listCheckCrops.filter(
      (el) => !el.isDisabledMistakeButton
    );
    const cropResults = [];

    const apiCall = (crop) => {
      const mistakes = crop.mistakeValue.map((el) => el.value);
      const params =
        mistakes.length > 0
          ? {
              flag: crop.flag,
              mistake_types: mistakes.join(',')
            }
          : {
              flag: crop.flag
            };
      return updateAuditReport(crop.id, params);
    };

    const onSuccess = (crop) => {
      const newCrop = {
        ...crop,
        mistakeSuccessMessage: true,
        mistakeErrorMessage: false
      };
      cropResults.push(newCrop);
    };

    const onError = (crop) => {
      const newCrop = {
        ...crop,
        mistakeSuccessMessage: false,
        mistakeErrorMessage: true
      };
      cropResults.push(newCrop);
    };

    const { result } = fetchQueued(sendErrorCrop, apiCall, onSuccess, onError);
    await result;

    setListCheckCrops(
      listCheckCrops.map((item) => {
        if (!item.isDisabledMistakeButton) {
          const newItem = cropResults.find((el) => el.id === item.id);
          return {
            ...newItem,
            isLoadingMistake: false,
            isDisabledMistakeButton: true
          };
        }
        return { ...item, isLoadingMistake: false };
      })
    );
  };

  const onChangePageSize = (newPagesize) => {
    setIsLoading(true);
    setPageSize(newPagesize.value);
    setPage(1);
    setAuditReportCheck({
      page: 1,
      page_size: newPagesize.value,
      ...parameters
    });
  };

  const pageSizes = [1, 5, 10, 50, 100, 500, 1000];

  const valuePages = pageSizes.map((el) => ({ label: el, value: el }));

  const showModal = (el) => {
    setDimensionsModal({});
    getCrop(el.fs_label_id, {
      photo_id: el.photo_id,
      centroid_scale: 100,
      photo_as_url: true
    }).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        const result = {
          url: data.crop,
          source_img_url: data.crop,
          x_min: data.rect.x,
          y_min: data.rect.y,
          x_max: data.rect.x + data.rect.width,
          y_max: data.rect.y + data.rect.height
        };
        setDimensionsModal(result);
        setShowSVGShapeModal(true);
      } else if (status === 204) {
        setErrorTextModal(
          'For this crop there is no original job image available.'
        );
        setShowModalError(true);
      } else {
        setErrorTextModal('Sorry, something went wrong');
        setShowModalError(true);
      }
    });
  };

  const onClickBBS = (value) => {
    setInfoBBS({ count: 0, label: value, isLoading: true, error: false });
    setResultBBS([]);
    setShowModalBBS(true);
    getAuditReportBBS({ name_like: value, page_size: 999 }).then(
      async (response) => {
        const status = await response.status;
        if (status === 200) {
          const data = await response.json();
          const newDataBBS = data.results;
          setResultBBS(newDataBBS);
          setInfoBBS({
            label: value,
            isLoading: false,
            error: false
          });
        } else {
          setInfoBBS({ label: value, isLoading: false, error: true });
        }
      }
    );
  };

  const filterCallback = (searchFsLabelId, searchPrediction, searchTagged) => {
    setIsLoading(true);
    const params = {};
    if (searchFsLabelId) params.fs_label_id = searchFsLabelId;
    if (searchPrediction) params.prediction = searchPrediction;
    if (searchTagged) params.tagged = searchTagged;
    setPage(1);
    setParameters(params);
    setAuditReportCheck({ page: 1, page_size: pageSize, ...params });
  };

  const isDisabledAllMistakeButton =
    listCheckCrops.findIndex((el) => !el.isDisabledMistakeButton) < 0;
  const isLoadingAllMistakeButton =
    isLoading || !(listCheckCrops.findIndex((el) => el.isLoadingMistake) < 0);

  return (
    <>
      <ModalWithSVGShape
        crop={dimensionsModal}
        handleClose={() => {
          setShowSVGShapeModal(false);
        }}
        show={showSVGShapeModal}
      />
      <Modal show={showModalError} onHide={() => setShowModalError(false)}>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>{errorTextModal}</Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showModalBBS}
        onHide={() => setShowModalBBS(false)}
      >
        <Modal.Header closeButton>
          <div>
            Brand Bank search result(s) for:
            <span className="font-weight-bolder"> {infoBBS?.label}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {infoBBS?.isLoading ? (
            <Spinner size="lg" animation="border" role="status" />
          ) : (
            <>
              {infoBBS?.error && <div>Sorry, something went wrong</div>}
              {!infoBBS?.error && resultBBS.length === 0 && (
                <Alert variant="dark">Nothing found</Alert>
              )}
              {!infoBBS?.error && resultBBS.length > 0 && (
                <div>
                  {resultBBS.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <SingleSKUReferenceItem
                        name={item.name}
                        selectedLabel=""
                        onClick={() => {}}
                        photos={item.photos}
                      />
                    </ListGroup.Item>
                  ))}
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <div
        className="d-flex justify-content-between"
        style={{
          background: '#fff',
          position: 'sticky',
          top: 0,
          borderBottom: '1px solid rgba(0,0,0,.125)',
          padding: '12px 16px',
          zIndex: 1035,
          margin: '-30px -15px 15px'
        }}
      >
        <Link to={backLink}>
          <Button variant="light">Back to SKU view</Button>
        </Link>
        {!isViewer && (
          <SpinnerButton
            isLoading={isLoadingAllMistakeButton}
            disabled={isDisabledAllMistakeButton}
            onClick={() => onSendAllMistakes()}
            value="Send All"
          />
        )}
      </div>
      <ProductionReportChecksFilters
        filterCallback={filterCallback}
        isLoadingSearch={isLoading}
      />
      {isLoading && <Spinner size="lg" animation="border" role="status" />}
      {error && (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      )}
      {!isLoading && !error && listCheckCrops.length === 0 && (
        <Alert variant="dark">
          Nothing to show. Please go to{' '}
          <Link to="/audit-reports">Audit Reports</Link>{' '}
        </Alert>
      )}
      {!isLoading && !error && listCheckCrops.length !== 0 && (
        <Card className="card-сrops">
          <Card.Header>
            <Card.Title as="h5">
              <Row>
                <Col xs="12" sm="8" lg="8" xl="9">
                  {label}
                </Col>
                <Col
                  xs="12"
                  sm="4"
                  lg="4"
                  xl="3"
                  className="text-right font-weight-bolder"
                >
                  <b>
                    Progress{' '}
                    <span className="text-nowrap">
                      {listCheckCrops[0]?.sku_progress.crops_checked_count}{' '}
                      (auto{' '}
                      {listCheckCrops[0]?.sku_progress.crop_auto_checked_count})
                    </span>
                    <span className="text-nowrap">
                      {' '}
                      / {listCheckCrops[0]?.sku_progress.crops_count}
                    </span>
                  </b>
                </Col>
              </Row>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {listCheckCrops.map((el) => (
              <ReportCheckRow
                key={el.id}
                crop={el}
                showModal={showModal}
                onClickBBS={onClickBBS}
                handleChangeState={handleChangeState}
                mistakesList={mistakesList}
                onSendMistakes={onSendMistakes}
                isLoading={isLoading}
                isViewer={isViewer}
              />
            ))}
          </Card.Body>
          <Card.Footer>
            <div className="stats d-flex  justify-content-between flex-wrap">
              <div className="text-nowrap pr-3 text-body mt-1 mb-2 h4">
                Total: {count}
              </div>
              {count > pageSize && (
                <ListPaginator
                  total={count}
                  current={page}
                  pageSize={pageSize}
                  previous={previous}
                  next={next}
                  changePage={loadData}
                />
              )}
              <div className="pl-3 mt-n1 text-nowrap">
                Crops per page
                <ReactSelect
                  value={valuePages[pageSizes.indexOf(pageSize)]}
                  onChange={onChangePageSize}
                  isDisabled={isLoading}
                  options={valuePages}
                  placeholder=""
                  className="pl-3 d-inline-block"
                  style={{ height: 35 }}
                />
              </div>
            </div>
          </Card.Footer>
        </Card>
      )}
    </>
  );
}

ProductionReportCheck.propTypes = {
  userType: PropTypes.string
};

ProductionReportCheck.defaultProps = {
  userType: ''
};

export default ProductionReportCheck;
