import IconLinkButton from 'components/Buttons/IconLinkButton';
import React, { FC } from 'react';

const UserDetails: FC<{
  labelerId?: string;
  reviewerId?: string;
  isLabeler: boolean;
}> = ({ labelerId, reviewerId, isLabeler }) => {
  let info: { name: string; role: string } | undefined;
  if (labelerId && !isLabeler) info = { name: labelerId, role: 'Labeler' };
  if (reviewerId && isLabeler) info = { name: reviewerId, role: 'Reviewer' };
  if (!info) return null;
  return (
    <IconLinkButton icon="user">
      <span className="text-black">{`${info.role}: ${info.name}`}</span>
    </IconLinkButton>
  );
};

UserDetails.displayName = 'UserDetails';

export default UserDetails;
