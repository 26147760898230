import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import Table from '../../components/Table/Table';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';
import StatisticsFilters from './StatisticsFilters';
import { getClusterStatistics } from '../../api/clusters';

function Statistics() {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [rowNames, setRowNames] = useState([]);
  const [parameters, setParameters] = useState({});
  const [paramsCSV, setParamsCSV] = useState({});
  const [errorMessageCSV, setErrorMessageCSV] = useState('');

  const getParams = (selected) => {
    const {
      clusterId,
      selectedUserTypeFilter,
      selectedSortOn,
      selectedJobType,
      selectedState,
      selectedSubState,
      selectedStation,
      selectedLabeler,
      selectedReviewer,
      selectedAction,
      startDate,
      endDate
    } = selected;

    const params = {
      page_size: PAGE_SIZE,
      job_type_id: selectedJobType.id
    };

    if (clusterId) params.cluster_id = clusterId;
    if (selectedUserTypeFilter.value !== 'all') params.user_type = selectedUserTypeFilter.value;
    if (selectedSortOn) params.ordering = selectedSortOn;
    if (selectedState?.value) params.state = selectedState.value;
    if (selectedSubState?.value) params.substate = selectedSubState.value;
    if (selectedStation?.value) params.station = selectedStation.value;
    if (selectedLabeler?.value) params.labeler = selectedLabeler.value;
    if (selectedReviewer?.value) params.reviewer = selectedReviewer.value;
    if (selectedAction?.value) params.action = selectedAction.value;
    if (startDate) params.updated_at__gte = startDate;
    if (endDate) params.updated_at__lte = `${endDate} 23:59:59.999`;

    if (selected?.page) params.page = selected.page;

    return params;
  };

  const columnNames = [
    'cluster_id',
    'state',
    'substate',
    'station',
    'user_type',
    'action',
    'updated_at',
    'time_for_action',
    'total_time_for_action',
    'total_time',
    'fix',
    'job_type',
    'labeler',
    'reviewer'
  ];
  const convertTime = (timestamp) => {
    const hours = Math.floor(timestamp / 60 / 60);
    const hoursString = hours > 0 ? `${hours}h ` : '';
    const min = Math.floor(timestamp / 60) - hours * 60;
    const minString = min > 0 ? `${min}m ` : '';
    const seconds = timestamp % 60;
    const zeroMinutes = hours > 0 && min === 0 ? '0m ' : '';
    return `${hoursString + minString + zeroMinutes}${seconds}s`;
  };

  const handleRequest = (params) => {
    const parameters = getParams(params);
    setLoading(true);

    getClusterStatistics(parameters).then(async (response) => {
      const data = await response.json();
      if (response.status !== 200) {
        setRowNames([]);
        setParamsCSV({});
        setErrorMessage(data.detail || 'Error');
        return;
      }
      setLoading(false);
      if (data && !data.results) return;

      const newParamsCSV = {
        parameters: {
          ...parameters,
          page: 1,
          page_size: data.count
        }
      };
      setParamsCSV(newParamsCSV);
      const rowNames = [];
      setErrorMessage('');
      setErrorMessageCSV('');

      const onCopyClick = (id) => {
        navigator.clipboard.writeText(id);
      };

      data.results.forEach((item) => {
        const obj = {
          cluster_id: (
            <span title={item.cluster_id} className="text-nowrap">
              {item.cluster_id.substring(0, 2)}...
              {item.cluster_id.substring(item.cluster_id.length - 2)}
              &nbsp;
              <i
                className="fa fa-copy"
                title="Copy"
                style={{ cursor: 'pointer' }}
                onClick={() => onCopyClick(item.cluster_id)}
              />
            </span>
          ),
          state: item.state,
          substate: item.substate,
          station: item.station,
          user_type: item.user_type,
          action: item.action,
          updated_at: moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
          time_for_action: convertTime(item.time_for_action),
          total_time_for_action: convertTime(item.total_time_for_action),
          total_time: convertTime(item.total_time),
          fix: `${item.fix}`,
          job_type: item.job_type,
          labeler: item.labeler,
          reviewer: item.reviewer
        };
        rowNames.push(obj);
      });
      setCount(data.count);
      setNext(data.next);
      setPrevious(data.previous);
      setRowNames(rowNames);
      setPage(parameters.page);
    });
  };

  const loadData = (params) => {
    setPage(params.page);
    handleRequest({ ...parameters, page: params.page });
  };

  const convertToCSV = (objArray) => {
    const columnsCSV = [
      ['id', 'id'],
      ['cluster id', 'cluster_id'],
      ['state', 'state'],
      ['sub-state', 'substate'],
      ['station', 'station'],
      ['user type', 'user_type'],
      ['action', 'action'],
      ['updated at', 'updated_at'],
      ['time for action', 'time_for_action'],
      ['total time for action', 'total_time_for_action'],
      ['total time', 'total_time'],
      ['fix', 'fix'],
      ['job Type', 'job_type'],
      ['labeler', 'labeler'],
      ['reviewer', 'reviewer']
    ];
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    let header = '';
    columnsCSV.forEach((column) => {
      if (header) header += ',';
      header += column[0];
    });
    let str = `${header}\r\n`;

    array.forEach((el) => {
      let line = '';

      columnsCSV.forEach((column) => {
        if (line) line += ',';
        switch (column) {
          case columnsCSV[7]:
            line += moment(el[column[1]]).format('YYYY-MM-DD HH:mm:ss');
            break;
          case columnsCSV[8]:
          case columnsCSV[9]:
          case columnsCSV[10]:
            if (el[column[1]]) {
              line += el[column[1]];
            } else line += '0';
            break;
          case columnsCSV[11]:
            if (el[column[1]]) {
              line += 'true';
            } else line += 'false';
            break;
          default:
            if (el[column[1]]) line += el[column[1]];
        }
      });
      str += `${line}\r\n`;
    });

    return str;
  };

  const downloadCSV = () => {
    const { parameters } = paramsCSV;
    setLoading(true);
    getClusterStatistics(parameters).then(async (response) => {
      const data = await response.json();
      if (response.status !== 200) {
        setErrorMessageCSV(data.detail || 'Download CSV Error');
        setLoading(false);
        return;
      }
      if (data && !data.results) {
        setErrorMessageCSV('Download CSV Error');
        setLoading(false);
        return;
      }
      setErrorMessageCSV('');

      const url = window.URL.createObjectURL(
        new Blob([convertToCSV(data.results)], { type: 'text/csv' })
      );
      const link = document.createElement('a');
      link.href = url;
      const fileName = `statistics.csv`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    });
  };

  const filterCallback = (params) => {
    const parameters = { ...params, page: 1 };
    handleRequest(parameters);
    setParameters(parameters);
  };

  return (
    <>
      <StatisticsFilters
        filterCallback={filterCallback}
        isLoading={isLoading}
      />
      {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
      <Table
        page={page}
        next={next}
        count={count}
        previous={previous}
        columnNames={columnNames}
        rowNames={rowNames}
        loadData={loadData}
        isLoading={isLoading}
      />
      <div>
        <SpinnerButton
          className="btn ml-2"
          onClick={downloadCSV}
          disabled={!paramsCSV?.parameters}
          isLoading={isLoading}
          variant="default"
          value="Download CSV"
        />
      </div>
      {errorMessageCSV && (
        <Alert variant="danger" className="mt-4 mb-0">
          {errorMessageCSV}
        </Alert>
      )}
    </>
  );
}

export default Statistics;
