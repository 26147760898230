export const IS_AUTO_LOAD_ON_KEY = 'isAutoLoadOn';
export const IS_LABELER_MODE_ON_KEY = 'isLabelerModeOn';

export type SettingsContext = {
  isAutoLoadOn: boolean;
  isLabelerMode: boolean;
  setAutoLoad: (isAutoLoadOn: boolean) => void;
  setLabelerMode: (isLabelerMode: boolean) => void;
};

export const initialState: SettingsContext = {
  isAutoLoadOn: localStorage.getItem(IS_AUTO_LOAD_ON_KEY) === 'true',
  isLabelerMode: localStorage.getItem(IS_LABELER_MODE_ON_KEY) === 'true',
  setAutoLoad: () => {},
  setLabelerMode: () => {},
};

type SettingsReducerAction =
  | { type: 'AutoLoadValueChanged'; isAutoLoadOn: boolean }
  | { type: 'LabelerModeValueChanged'; isLabelerMode: boolean };

export function SettingsReducer(
  state: SettingsContext,
  action: SettingsReducerAction,
): SettingsContext {
  switch (action.type) {
    case 'AutoLoadValueChanged':
      return { ...state, isAutoLoadOn: action.isAutoLoadOn };
    case 'LabelerModeValueChanged':
      return { ...state, isLabelerMode: action.isLabelerMode };
    default:
      return state;
  }
}
