/* eslint-disable no-console */
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IconLinkButton from 'components/Buttons/IconLinkButton';
import TabView, { TabViewItem } from 'components/TabView/TabView';
import CropsList from 'components/CropLists/CropsList';
import useSettings from 'contexts/SettingsContext';
import { useUserContext } from 'contexts/Users';
import Switch from 'components/Buttons/Switch';
import { ClusterDataTO, JobTypeTO, PointTO } from 'api/api.types';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import { HttpStatus } from 'enums';
import { assignCluster, ClusterAction, getClusterAction } from 'api/dataLayer';
import { getJobTypes } from 'api/jobTypes';
import { cropsPlural, nextId } from 'utils';
import ConfirmModal from 'modals/ConfirmModal';
import RejectInfoComponent from 'components/CropLists/RejectInfoComponent';
import { reorderPoints } from 'api/crops';
import CropActionsMenu from './CropActionsMenu';
import CropView from './CropView';
import CropShelfView from './CropSelfView';
import HistoryButton from './HistoryButton';
import UserDetails from './UserDetails';
import ClusterActionButtons from './ClusterActionButtons';

export type Point = PointTO & {
  clusterId: string;
  sortNumber: number;
};

export type Cluster = {
  id: string;
  name: string;
};

export const MAIN_CLUSTER_ID = '0';
const MAIN_CLUSTER_NAME = 'Main';
const DEFAULT_CLUSTER = { id: MAIN_CLUSTER_ID, name: MAIN_CLUSTER_NAME };

const ERROR_MESSAGE = 'Sorry something went wrong';

const CleaningStation = (): JSX.Element | undefined => {
  const [jobTypes, setJobTypes] = useState<JobTypeTO[]>([]);
  const [selectedJobType, setSelectedJobType] = useState<JobTypeTO | null>(
    null,
  );
  const [clusterData, setCulsterData] = useState<ClusterDataTO | null>(null);
  const [clusters, setClusters] = useState<Cluster[]>([DEFAULT_CLUSTER]);
  const [selectedClusterId, setSelectedClusterId] =
    useState<string>(MAIN_CLUSTER_ID);
  const [crops, setCrops] = useState<Point[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<{ [key: string]: any }>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRejected, setIsRejected] = useState(false);
  const [cropViewId, setCropViewId] = useState<string | undefined>();
  const [shelfViewCropId, setShelfViewCropId] = useState<string | null>(null);
  const [paused, setPaused] = useState(false);
  const { isLabelerMode, setLabelerMode } = useSettings();
  const { isLabeler, isExternal, assignJobTypes, setAssignJobTypes } =
    useUserContext();
  const { handleJobTypeChange } = useBatchLabelingContext();
  const [showRemoveClusterModal, setShowRemoveClusterModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const clearData = useCallback(() => {
    setCulsterData(null);
    // setSelectedJobType(null);
    setCrops([]);
    setSelectedCrops({});
    setClusters([DEFAULT_CLUSTER]);
    setIsRejected(false);
    setErrorMessage('');
  }, []);

  const assignLabeler = useCallback(
    async (jobType: JobTypeTO | null) => {
      if (!jobType) {
        setLoading(false);
        return;
      }
      setLoading(true);
      setSelectedJobType(jobType);
      setIsRejected(false);
      setErrorMessage('');

      try {
        const { status, data } = await assignCluster(
          isLabeler || isLabelerMode,
          jobType.predefined_classes_id,
        );
        switch (status) {
          case HttpStatus.SUCCESS:
            const isNewCluster = clusterData?.id !== data.id;
            if (isNewCluster) {
              setSelectedJobType(jobType);
              setSelectedCrops({});
              setClusters([DEFAULT_CLUSTER]);
            }
            const clustersToKeep: any = {};
            setCrops(
              data.points.map((point, index) => {
                const prevCrop = isNewCluster
                  ? undefined
                  : crops.find((c) => c.id === point.id);
                if (prevCrop?.clusterId)
                  clustersToKeep[prevCrop?.clusterId] = true;
                return {
                  ...point,
                  clusterId: prevCrop?.clusterId ?? MAIN_CLUSTER_ID,
                  sortNumber: prevCrop?.sortNumber ?? index, // TODO: is there a better way to keep crops sorted? Keeped this for same UX as on prev UI
                };
              }),
            );
            if (!isNewCluster)
              setClusters(
                clusters.filter(
                  ({ id }) => id === MAIN_CLUSTER_ID || clustersToKeep[id],
                ),
              );

            setIsRejected(data.reject_flag);
            setCulsterData(data);
            break;
          case HttpStatus.NO_CONTENT:
            clearData();
            setErrorMessage('There are no clusters to proceed');
            break;
          default:
            clearData();
            setErrorMessage(data.detail || '');
            break;
        }
      } catch (error) {
        console.error('Error assigning user:', error);
      } finally {
        setLoading(false);
      }
    },
    [clearData, clusterData?.id, clusters, crops, isLabeler, isLabelerMode],
  );

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        setLoading(true);

        const response = await getJobTypes({
          for_clustering: true,
          page_size: 999,
        });
        const data = await response.json();
        const jobTypeList = data.results;
        if (isLabeler) {
          setAssignJobTypes();
        } else {
          setLoading(false);
        }
        setJobTypes(jobTypeList);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching job types:', error);
      }
    };
    fetchJobTypes();
  }, [isLabeler, setAssignJobTypes]);

  // Auto setting job type for labeler
  useEffect(() => {
    if (!assignJobTypes.length) return;
    if (selectedJobType?.id === assignJobTypes[0]) return;
    const assignedJobType = jobTypes.find(
      (jobType) => jobType.id === assignJobTypes[0],
    );
    if (!assignedJobType) return;
    setSelectedJobType(assignedJobType);
    assignLabeler(assignedJobType);
  }, [assignJobTypes, assignLabeler, jobTypes, selectedJobType?.id]);

  const refreshData = () => {
    setSelectedCrops([]);
    if (paused) {
      clearData();
      setLoading(false);
      return;
    }
    assignLabeler(selectedJobType);
    setSelectedCrops([]);
  };

  // TODO: TS me?
  const checkResponse = async (response: any) => {
    if (response.status === 200) {
      refreshData();
    } else {
      await response
        .json()
        .then((data: any) => setErrorMessage(data.detail || ERROR_MESSAGE))
        .catch(() => setErrorMessage(ERROR_MESSAGE));
    }
  };

  const clusterCrops = useMemo(
    () =>
      Object.fromEntries(
        clusters.map((cluster) => [
          cluster.id,
          crops
            .filter((crop) => crop.clusterId === cluster.id)
            .sort((a, b) => a.sortNumber - b.sortNumber),
        ]),
      ),
    [clusters, crops],
  );

  const selectedClusterSelectedCrops: Point[] = useMemo(
    () =>
      (clusterCrops[selectedClusterId] ?? []).filter(
        ({ id }) => selectedCrops[id],
      ),
    [clusterCrops, selectedClusterId, selectedCrops],
  );

  const disableCropsMoving =
    selectedClusterId === MAIN_CLUSTER_ID &&
    selectedClusterSelectedCrops.length &&
    selectedClusterSelectedCrops.length ===
      clusterCrops[MAIN_CLUSTER_ID].length;

  const onSelectJobType = (jobType: JobTypeTO) => {
    assignLabeler(jobType);
    handleJobTypeChange(jobType);
  };

  const onSelectLabelerMode = (mode: boolean) => {
    setLabelerMode(mode);
    setSelectedJobType(null);
    clearData();
  };

  const onSelectCrop = (cropId: string) => {
    if (selectedCrops[cropId]) {
      const updSelectedCrops = { ...selectedCrops };
      delete updSelectedCrops[cropId];
      setSelectedCrops(updSelectedCrops);
    } else {
      setSelectedCrops({ ...selectedCrops, [cropId]: true });
    }
  };

  const onUpdateSelections = (type: 'allBelow' | 'unselectAll' | 'invert') => {
    const updSelectedCrops = { ...selectedCrops };
    switch (type) {
      case 'allBelow':
        if (!selectedClusterSelectedCrops.length) return;
        const maxSelectedSortNumber =
          selectedClusterSelectedCrops[selectedClusterSelectedCrops.length - 1]
            .sortNumber;
        (clusterCrops[selectedClusterId] ?? [])
          .filter(({ sortNumber }) => sortNumber > maxSelectedSortNumber)
          .forEach(({ id }) => {
            updSelectedCrops[id] = true;
          });
        break;
      case 'unselectAll':
        selectedClusterSelectedCrops.forEach(({ id }) => {
          delete updSelectedCrops[id];
        });
        break;
      case 'invert':
        (clusterCrops[selectedClusterId] ?? []).forEach(({ id }) => {
          if (selectedCrops[id]) delete updSelectedCrops[id];
          else updSelectedCrops[id] = true;
        });
        break;
    }
    setSelectedCrops(updSelectedCrops);
  };

  const onSortBySelected = async () => {
    setLoading(true);
    const cropForSort = crops
      .filter(
        ({ id, clusterId }) =>
          selectedCrops[id] && clusterId === MAIN_CLUSTER_ID,
      )
      .map(({ id }) => id);
    const cropForNegativeSort = crops
      .filter(({ clusterId }) => clusterId !== MAIN_CLUSTER_ID)
      .map(({ id }) => id);
    const props = {
      points: cropForSort,
      negative_points: cropForNegativeSort,
    };
    const response = await reorderPoints(clusterData?.id, props);

    if (response.status === 200) {
      await response
        .json()
        .then((data) => {
          const cropSorter = data.point_ids;
          const selectedCropsLength = Object.keys(selectedCrops).length;
          const sortedCropsLength = cropSorter.length;
          setCrops(
            crops.map((crop) => {
              const updatedCrop = { ...crop };
              if (selectedCrops[crop.id]) {
                updatedCrop.sortNumber = cropForSort.indexOf(crop.id);
              } else if (cropSorter.includes(crop.id)) {
                updatedCrop.sortNumber =
                  selectedCropsLength + cropSorter.indexOf(crop.id);
              } else if (cropForNegativeSort.includes(crop.id)) {
                updatedCrop.sortNumber =
                  selectedCropsLength +
                  sortedCropsLength +
                  cropForNegativeSort.indexOf(crop.id);
              }
              return updatedCrop;
            }),
          );
        })
        .catch(() => setErrorMessage(ERROR_MESSAGE));
    } else {
      setErrorMessage(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const onMoveToCluster = (id: string, updatedClusters = clusters) => {
    const updatedCrops = crops.map((crop) =>
      crop.clusterId === selectedClusterId && selectedCrops[crop.id]
        ? { ...crop, clusterId: id }
        : crop,
    );
    setCrops(updatedCrops);
    const filteredClusters = updatedClusters.filter((cluster) =>
      updatedCrops.find(({ clusterId }) => cluster.id === clusterId),
    );
    setClusters(filteredClusters);
    if (!filteredClusters.find((cluster) => cluster.id === selectedClusterId)) {
      setSelectedClusterId(id);
    }
    onUpdateSelections('unselectAll');
  };

  const onMoveToNewCluster = (name: string) => {
    const newCluster: Cluster = { id: nextId().toString(), name };
    const updatedClusters = [...clusters, newCluster];
    onMoveToCluster(newCluster.id, updatedClusters);
  };

  const performClusterAction = async (
    action: ClusterAction,
    comment?: string,
  ) => {
    if (loading) return;
    setLoading(true);
    if (!clusterData) return;
    await checkResponse(
      await getClusterAction(action, clusterData.id, {
        fix: !(isLabeler || isLabelerMode),
        comment,
        cropIds: selectedClusterSelectedCrops.map(({ id }) => id),
        clusterCrops,
      }),
    );
  };

  const onShowCropView = (cropId: string) => {
    setCropViewId(cropId);
  };

  const onCloseCropView = () => {
    setCropViewId(undefined);
  };

  const onShowShelfView = (cropId: string) => {
    setShelfViewCropId(cropId || null);
  };

  const onResumeWork = () => {
    setPaused(false);
    if (!selectedJobType) return;
    assignLabeler(selectedJobType);
  };

  // TODO: Why?
  // const stopLoadingFunction = (value) => {
  //   setStopLoading(value);
  // };

  // TODO: Why is this needed?
  if (isExternal) return;
  return (
    <Container fluid className="new-ui station-container">
      <Card className="header-box">
        <Card.Header className="card-dark-header py-2">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span className="text-black-75 py-1 mr-3 d-inline-block">
                Job Type{isLabeler && ':'}
              </span>
              {isLabeler ? (
                <span className="py-2 mr-3 d-inline-block">
                  {selectedJobType?.name}
                </span>
              ) : (
                <div className="d-inline-block my-1">
                  <JobTypeDropdown
                    predefinedClass
                    onClickJobType={(jobType) => onSelectJobType(jobType)}
                    jobTypeList={jobTypes}
                    newUI
                  />
                </div>
              )}
            </div>
            <div>
              {paused ? (
                <IconLinkButton icon="resume" onClick={onResumeWork}>
                  Resume my work
                </IconLinkButton>
              ) : (
                <IconLinkButton icon="pause" onClick={() => setPaused(true)}>
                  Pause my work
                </IconLinkButton>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {errorMessage && (
            <Row className="mt-n3">
              <Col>
                <Alert variant="danger">{errorMessage}</Alert>
              </Col>
            </Row>
          )}
          {isRejected && clusterData && (
            <Row>
              <Col>
                <RejectInfoComponent key={clusterData.id} id={clusterData.id} />
              </Col>
            </Row>
          )}
          <Row className="mt-n3">
            <Col>
              <div className="h4 mt-3">
                {clusterData
                  ? `Cluster ID: ${clusterData?.id}`
                  : 'Not selected'}
              </div>
              <div className="mt-3">
                <HistoryButton clusterId={clusterData?.id} />
                <IconLinkButton
                  icon="trash"
                  className="mr-3"
                  disabled={!clusterData?.id}
                  onClick={() => setShowRemoveClusterModal(true)}>
                  <span className="text-red ">Remove Cluster</span>
                </IconLinkButton>
                <UserDetails
                  labelerId={clusterData?.labeler_id}
                  reviewerId={clusterData?.reviewer_id}
                  isLabeler={isLabeler || isLabelerMode}
                />
              </div>
            </Col>
            <Col md="auto" className="mt-2">
              <ClusterActionButtons
                key={clusterData?.id}
                performAction={performClusterAction}
                isLabeler={isLabeler || isLabelerMode}
                clusterLoaded={!!clusterData}
                clusters={clusters}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="body-box mt-n1">
        <TabView
          activeKey={selectedClusterId}
          onSelect={(key) => setSelectedClusterId(key || MAIN_CLUSTER_ID)}>
          {clusters.map(({ id, name }) => {
            const cropsCount = (clusterCrops[id] || []).length;
            return (
              <TabViewItem
                tabKey={id}
                label={`${name} (${cropsCount} ${cropsPlural(cropsCount)})`}
                key={id}>
                <CropsList
                  crops={clusterCrops[id] || []}
                  selectedCrops={selectedCrops}
                  onSelectCrop={onSelectCrop}
                  empty={!clusterData?.id}
                  paused={paused}
                  loading={loading}
                  onUpdateSelections={onUpdateSelections}
                  onShowCropView={onShowCropView}
                  onShowShelfView={onShowShelfView}
                  onSortBySelected={onSortBySelected}
                />
              </TabViewItem>
            );
          })}
        </TabView>
      </div>
      <CropActionsMenu
        cropsCount={selectedClusterSelectedCrops.length}
        clusters={clusters}
        selectedClusterId={selectedClusterId}
        disableCropsMoving={!!disableCropsMoving}
        onMoveToNewCluster={onMoveToNewCluster}
        onMoveToCluster={onMoveToCluster}
        onRemoveCrops={() => performClusterAction('removeSelectedCrops')}
        onDeselectAll={() => onUpdateSelections('unselectAll')}
      />
      <div className="d-flex mb-2 justify-content-end labeler-switch">
        {!isLabeler && !cropViewId && (
          <Switch
            name="1"
            checked={isLabelerMode}
            setChecked={onSelectLabelerMode}
            label="Labeler View Mode"
          />
        )}
      </div>
      <CropView
        crops={clusterCrops[selectedClusterId]}
        currentCropId={cropViewId}
        selectedCrops={selectedCrops}
        onCloseCropView={onCloseCropView}
        onSelectCrop={onSelectCrop}
        onShowShelfView={onShowShelfView}
      />
      <CropShelfView
        cropId={shelfViewCropId}
        onClose={() => setShelfViewCropId(null)}
      />
      <ConfirmModal
        header="Remove Cluster"
        confrimLabel="Remove Cluster"
        cancelLablel="Cancel"
        show={showRemoveClusterModal}
        onCancel={() => setShowRemoveClusterModal(false)}
        onConfirm={() => {
          setShowRemoveClusterModal(false);
          performClusterAction('removeCluster');
        }}>
        Do you want to remove cluster?
      </ConfirmModal>
    </Container>
  );
};
CleaningStation.displayName = 'CleaningStation';

export default CleaningStation;
