// TODO: fixme
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ClusterAction } from 'api/dataLayer';
import ConfirmModal from 'modals/ConfirmModal';
import React, { FC, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Cluster } from './Cleaning';

const ClusterActionButtons: FC<{
  performAction: (action: ClusterAction, comment?: string) => Promise<void>;
  isLabeler: boolean;
  clusterLoaded: boolean;
  clusters: Cluster[];
}> = ({ performAction, isLabeler, clusterLoaded, clusters }) => {
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [rejectComment, setRejectComment] = useState('');

  /* const rejectCommentRef = useRef<any>(null);
  useEffect(() => {
    rejectCommentRef.current?.focus(); // TODO: Does not fire. Fix.
  }, []); */

  const onConfirmReject = () => {
    setShowRejectDialog(false);
    if (rejectComment) performAction('rejectClusterWithComment', rejectComment);
    else performAction('reject');
  };

  const rejectCommentTooLong = rejectComment.length > 1024;

  return (
    <>
      {clusterLoaded && clusters.length > 1 && (
        <Button
          variant="outline-secondary"
          onClick={() => performAction('acceptAllNewClusters')}>
          Accept All New Clusters
        </Button>
      )}
      {!isLabeler && clusterLoaded && (
        <Button
          variant="outline-danger"
          className="ml-2"
          onClick={() => setShowRejectDialog(true)}>
          Reject
        </Button>
      )}
      {isLabeler ? (
        <Button
          disabled={!clusterLoaded}
          variant="fill"
          className="ml-2" // TODO: This should embed in buttons, see bootstrap version
          onClick={() => performAction('send')}>
          Send
        </Button>
      ) : (
        <Button
          disabled={!clusterLoaded}
          variant="fill"
          className="ml-2"
          onClick={() => performAction('accept')}>
          Accept
        </Button>
      )}
      <ConfirmModal
        header="Reject cluster"
        confrimLabel="Reject Cluster"
        cancelLablel="Cancel"
        show={showRejectDialog}
        disabled={rejectCommentTooLong}
        onCancel={() => setShowRejectDialog(false)}
        onConfirm={() => onConfirmReject()}>
        <label>Add a comment (optional)</label>
        <Form>
          <FormControl // TODO: Format with proper label
            as="textarea"
            rows={3}
            required
            className="form-range"
            value={rejectComment}
            onChange={(e) => setRejectComment(e.target.value)}
          />
          {rejectCommentTooLong && (
            <div className="error-text pt-2">
              comment can&apos;t be longer than 1024 symbols
            </div>
          )}
        </Form>
      </ConfirmModal>
    </>
  );
};
ClusterActionButtons.displayName = 'ClusterActionButtons';

export default ClusterActionButtons;
