import { userRole } from 'enums';
import Dashboard from 'views/Dashboard/Dashboard';
import Login from 'views/Login';
import UserProfile from 'views/UserProfile';
import CreateCrop from 'views/CreateCrop';
import CreateManyCropJobRequests from 'views/CreateManyCropJobRequests';
import ImageFinder from 'views/ImageFinder/ImageFinder';
import PeriodicTasks from 'views/ImageFinder/PeriodicTasks';
import ImageFinderIndexes from 'views/ImageFinder/ImageFinderIndexes';
import CropList from 'views/ListView/CropList';
import JobList from 'views/ListView/JobList';
import TaskResults from 'views/TaskResults/TaskResults';
import CropLabeling from 'views/CropLabelingProcess/CropLabeling';
import CropReviewing from 'views/CropLabelingProcess/CropReviewing';
import BatchCropLabeling from 'views/CropLabelingProcess/BatchCropLabeling';
import BatchCropReviewing from 'views/CropLabelingProcess/BatchCropReviewing';
import JobProgress from 'views/JobProgress/JobProgress';
import LabelerAnalytics from 'views/LabelerAnalytics/LabelerAnalytics';
import LabelersJobTypes from 'views/LabelersJobTypes';
import ImageImporter from 'views/ImageImporter/ImageImporter';
import LabelerPerformance from 'views/Performance/LabelerPerformance';
import QCPerformance from 'views/Performance/QCPerformance';
import Clusters from 'views/Clusters/Clusters';
import PreFiltering from 'views/Clusters/PreFiltering';
import Identification from 'views/Clusters/Identification';
import ClusterFinder from 'views/Clusters/ClusterFinder';
import Manage from 'views/Clusters/Manage';
import ProductionReports from 'views/ProductionReports/ProductionReports';
import ProductionReportDetails from 'views/ProductionReports/ProductionReportDetails';
import ProductionReportChecks from 'views/ProductionReports/ProductionReportChecks';
import CleaningBak from 'views/Clusters/CleaningBak';
import CleaningStation from 'views/Clusters/Cleaning/Cleaning';
import Statistics from '../views/Clusters/Statistics';
import FastLane from '../views/Clusters/FastLane';

const USE_NEW_UI = true;

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
    private: true,
    access: [userRole.labeler, userRole.admin, userRole.qc],
  },
  {
    path: '/crop-job-list',
    name: 'Crops',
    icon: 'nc-icon nc-stre-right',
    component: CropList,
    layout: '/admin',
    private: true,
    access: [userRole.admin],
    children: [
      {
        path: '/new-many-crop-jobs',
        name: 'Create Many',
        icon: 'nc-icon nc-stre-right',
        component: CreateManyCropJobRequests,
        layout: '/admin',
        private: true,
        access: [userRole.admin],
      },
      {
        path: '/new-crop-jobs',
        name: 'Create Jobs',
        icon: 'nc-icon nc-stre-right',
        component: CreateCrop,
        layout: '/admin',
        private: true,
        access: [userRole.admin],
      },
    ],
  },
  {
    path: '/batch-reviewing',
    name: 'Batch Reviewing',
    icon: 'nc-icon nc-stre-right',
    component: BatchCropReviewing,
    layout: '/admin',
    private: true,
    access: [userRole.qc, userRole.admin, userRole.external],
    children: [
      {
        path: '/crops-reviewer',
        name: 'Crop Reviewing',
        icon: 'nc-icon nc-stre-right',
        component: CropReviewing,
        layout: '/admin',
        private: true,
        access: [userRole.qc, userRole.admin],
      },
    ],
  },
  {
    path: '/batch-labeling',
    name: 'Batch Labeling',
    icon: 'nc-icon nc-stre-right',
    component: BatchCropLabeling,
    layout: '/admin',
    private: true,
    access: [userRole.labeler, userRole.qc, userRole.admin],
    children: [
      {
        path: '/crops-labeling',
        name: 'Crop Labeling',
        icon: 'nc-icon nc-stre-right',
        component: CropLabeling,
        layout: '/admin',
        private: true,
        access: [userRole.labeler, userRole.qc, userRole.admin],
      },
    ],
  },
  {
    path: '/jobs',
    name: 'Jobs',
    icon: 'nc-icon nc-stre-right',
    component: JobList,
    layout: '/admin',
    private: true,
    access: [userRole.qc, userRole.admin],
  },
  {
    path: '/task-results',
    name: 'Task Results',
    icon: 'nc-icon nc-stre-right',
    component: TaskResults,
    layout: '/admin',
    private: true,
    access: [userRole.admin],
    children: [],
  },
  {
    path: '/image-importer',
    name: 'Image Importer',
    icon: 'nc-icon nc-stre-right',
    component: ImageImporter,
    layout: '/admin',
    private: true,
    access: [userRole.admin],
    children: [],
  },
  {
    path: '/image-finder',
    name: 'Image Finder',
    icon: 'nc-icon nc-stre-right',
    component: ImageFinder,
    layout: '/admin',
    private: true,
    access: [userRole.labeler, userRole.qc, userRole.admin, userRole.external],
    children: [
      {
        path: '/image-finder-indexes',
        name: 'IF Indexes',
        icon: 'nc-icon nc-stre-right',
        component: ImageFinderIndexes,
        layout: '/admin',
        private: true,
        access: [userRole.admin],
      },
      {
        path: '/periodic-task',
        name: 'Periodic Task',
        icon: 'nc-icon nc-stre-right',
        component: PeriodicTasks,
        layout: '/admin',
        private: true,
        access: [userRole.admin],
      },
    ],
  },
  {
    path: '/job-types-progress',
    name: 'Job types progress',
    icon: 'nc-icon nc-stre-right',
    component: JobProgress,
    layout: '/admin',
    private: true,
    access: [userRole.admin, userRole.qc],
  },
  {
    path: '/performance',
    name: 'Performance',
    icon: 'nc-icon nc-stre-right',
    component: LabelerPerformance,
    layout: '/admin',
    private: true,
    access: [userRole.labeler],
    children: [],
  },
  {
    path: '/labeler-analytics',
    name: 'Labeler Analytics',
    icon: 'nc-icon nc-stre-right',
    component: LabelerAnalytics,
    layout: '/admin',
    private: true,
    access: [userRole.admin, userRole.qc],
    children: [
      {
        path: '/labeler-performance',
        name: 'Labeler Performance',
        icon: 'nc-icon nc-stre-right',
        component: LabelerPerformance,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc],
      },
      {
        path: '/qc-performance',
        name: 'QC Performance',
        icon: 'nc-icon nc-stre-right',
        component: QCPerformance,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc],
      },
    ],
  },
  {
    path: '/labelers-job-types',
    name: 'Labelers JobTypes',
    icon: 'nc-icon nc-stre-right',
    component: LabelersJobTypes,
    layout: '/manage',
    private: true,
    access: [userRole.admin],
  },
  {
    path: '/audit-reports',
    name: 'Audit Reports',
    icon: 'nc-icon nc-stre-right',
    component: ProductionReports,
    layout: '/admin',
    private: true,
    access: [userRole.admin, userRole.viewer],
    children: [
      {
        path: '/audit-report-details/:id',
        name: 'Audit report details',
        icon: 'nc-icon nc-stre-right',
        component: ProductionReportDetails,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.viewer],
        notVisible: true,
      },
      {
        path: '/check/:id',
        name: 'Audit report checks',
        icon: 'nc-icon nc-stre-right',
        component: ProductionReportChecks,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.viewer],
        notVisible: true,
      },
    ],
  },
  {
    path: '/clusters',
    name: 'Clustering',
    tabName: 'Clusters',
    icon: 'nc-icon nc-stre-right',
    component: Clusters,
    layout: '/admin',
    private: true,
    access: [userRole.admin, userRole.qc, userRole.labeler, userRole.viewer],
    children: [
      {
        path: '/pre-filtering',
        name: 'Pre-filtering',
        icon: 'nc-icon nc-stre-right',
        component: PreFiltering,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc, userRole.labeler],
      },
      !USE_NEW_UI ? {
        path: '/cleaning_bak',
        name: 'Cleaning B',
        icon: 'nc-icon nc-stre-right',
        component: CleaningBak,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc, userRole.labeler],
      } : {
        path: '/cleaning',
        name: 'Cleaning',
        icon: 'nc-icon nc-stre-right',
        component: CleaningStation,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc, userRole.labeler],
      },
      {
        path: '/identification',
        name: 'Identification',
        icon: 'nc-icon nc-stre-right',
        component: Identification,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc, userRole.labeler],
      },
      {
        path: '/fast_lane',
        name: 'Fast Lane',
        icon: 'nc-icon nc-stre-right',
        component: FastLane,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc, userRole.labeler],
      },
      {
        path: '/cluster-finder',
        name: 'Cluster Finder',
        icon: 'nc-icon nc-stre-right',
        component: ClusterFinder,
        layout: '/admin',
        private: true,
        access: [
          userRole.admin,
          userRole.qc,
          userRole.labeler,
          userRole.viewer,
        ],
      },
      {
        path: '/manage',
        name: 'Manage',
        icon: 'nc-icon nc-stre-right',
        component: Manage,
        layout: '/admin',
        private: true,
        access: [userRole.admin, userRole.qc],
      },
      {
        path: '/statistics',
        name: 'Statistics',
        icon: 'nc-icon nc-stre-right',
        component: Statistics,
        layout: '/admin',
        private: true,
        access: [userRole.admin],
      },
    ],
  },
  {
    path: '/user',
    name: 'User Profile',
    icon: 'nc-icon nc-circle-09',
    component: UserProfile,
    layout: '/admin',
    private: true,
    access: [userRole.labeler, userRole.admin, userRole.qc, userRole.external],
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'nc-icon nc-cloud-upload-94',
    component: Login,
    layout: '/admin',
    private: false,
    access: [userRole.labeler, userRole.admin, userRole.qc, userRole.viewer],
  },
];

export default dashboardRoutes;
