export const JobState = Object.freeze({
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  RETURNED: 'RETURNED',
  AWAITING_QC: 'AWAITING_QC',
  FINISHED: 'FINISHED',
  FIX: 'FIX',
  FINISHED_WITHOUT_QC: 'FINISHED_WITHOUT_QC'
});

export const JobStateChoices = Object.freeze({
  RETURNED: 'Returned',
  NOT_ASSIGNED: 'Not assigned',
  RESERVED: 'Reserved',
  ASSIGNED: 'Assigned',
  STARTED: 'Started',
  AWAITING_QC: 'Awaiting for QC',
  IN_QC: 'In QC',
  FINISHED: 'Finished',
  PAID: 'Paid'
});

export const LabelerStateChoices = Object.freeze({
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  BANNED: 'Banned',
  TEST: 'Test'
});

export const UserType = Object.freeze({
  REVIEWER: 0,
  LABELER: 1
});

export const HttpStatus = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  FORBIDDEN: 403,
  BAD_REQUEST: 400
};

export const userRole = {
  labeler: 'NORMAL',
  qc: 'QC',
  external: 'EXTERNAL',
  admin: 'STAFF',
  viewer: 'VIEWER'
};

export const ImageFinderSelectors = {
  'Equal to': '$eq',
  'Not equal to': '$ne'
};

export const clusterStations = {
  'PRE-FILTERING': '/pre-filtering',
  CLEANING: '/cleaning',
  IDENTIFICATION: '/identification',
  'CLUSTER FINDER': '/cluster-finder',
  MANAGE: '/manage',
  'FAST LANE': '/fast_lane'
};
