import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Accordion, Spinner } from 'react-bootstrap';
import {
  getAuditReportDetails,
  getStatsPerDay,
  getStatsFlagsAndMistakes
} from 'api/auditReport';
import Table from 'components/Table/Table';
import {
  Link,
  useParams,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import ProductionReportDetailFilters from './ProductionReportDetailFilters';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';

function InfoCard({ id, parentCrop }) {
  const [isLoadingPerDay, setIsLoadingPerDay] = useState(true);
  const [infoPerDay, setInfoPerDay] = useState([]);
  const [errorPerDay, setErrorPerDay] = useState(false);
  const [isLoadingMistakes, setIsLoadingMistakes] = useState(true);
  const [infoMistakes, setInfoMistakes] = useState([]);
  const [errorMistakes, setErrorMistakes] = useState(false);

  const styles = {
    headerContainer: {
      position: 'relative',
      cursor: 'pointer'
    },
    wrapper: {
      position: 'absolute',
      left: 15,
      right: 15,
      background: 'inherit',
      zIndex: 101,
      marginTop: 2,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px'
    },
    wrapper2: {
      position: 'absolute',
      left: 15,
      right: 15,
      background: 'inherit',
      zIndex: 100,
      marginTop: 2,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px'
    },
    subContainer: {
      maxHeight: '50vh',
      overflow: 'auto'
    },
    topPlus: {
      position: 'absolute',
      right: 16,
      top: 16
    },
    topMinus: {
      position: 'absolute',
      right: 16,
      top: -37,
      background: '#fff',
      pointerEvents: 'none'
    }
  };

  useEffect(() => {
    getStatsFlagsAndMistakes(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response
          .json()
          .then((data) => {
            const { mistake_types, flags } = data;
            const mistakeTypesArr = [];
            for (const key in mistake_types) {
              if (Object.hasOwn(mistake_types, key)) {
                mistakeTypesArr.push({ label: key, value: mistake_types[key] });
              }
            }
            setInfoMistakes({
              flags,
              mistake_types: mistakeTypesArr
            });
            setErrorMistakes(false);
          })
          .catch(() => setErrorMistakes(true));
      } else {
        setErrorMistakes(true);
      }
      setIsLoadingMistakes(false);
    });
  }, [id]);

  const onShowStatsPerDay = () => {
    if (infoPerDay.length) return;
    const params = { ordering: 'date' };
    getStatsPerDay(id, params).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response
          .json()
          .then((data) => {
            setInfoPerDay(data.results);
            setErrorPerDay(false);
          })
          .catch(() => setErrorPerDay(true));
      } else {
        setErrorPerDay(true);
      }
      setIsLoadingPerDay(false);
    });
  };

  return (
    <Card>
      <Card.Body className="p-3">
        <Row>
          <Col xs="12" md="6">
            <span className="text-muted">Client:</span> {parentCrop?.client}
            <br />
            <span className="text-muted">Containers:</span>{' '}
            {parentCrop?.containers.join(',  ')}
            <br />
            <span className="text-muted">Model:</span> {parentCrop?.model}
            <br />
            <span className="text-muted">Th. Model:</span>{' '}
            {parentCrop?.threshold_model}
            <span> / </span>
            <span className="text-muted">Th. Analysis:</span>{' '}
            {parentCrop?.threshold_analysis}
            <br />
            <span className="text-muted">Created:</span>{' '}
            {parentCrop?.created_at}
            <br />
            <span className="text-muted">Accuracy:</span> {parentCrop?.accuracy}
            <br />
            <span className="text-muted">Errors count:</span>{' '}
            {parentCrop?.crops_checked_count} / {parentCrop?.error_count}
            <span> (&nbsp;{parentCrop?.errors_count_percent}&nbsp;)</span>
          </Col>
          <Col xs="12" md="6">
            <Row>
              <Col xs="12" md="5">
                <div className="pt-2 mt-1">
                  <span className="text-muted">Images:</span>{' '}
                  {parentCrop?.images_count}
                  <span> / </span>
                  <span className="text-muted">Crops:</span>{' '}
                  {parentCrop?.crops_count}
                </div>
                <div>
                  <span className="text-muted">Days:</span>{' '}
                  {parentCrop?.days_duration}
                  <ul>
                    <li>
                      <span className="text-muted">From:</span>{' '}
                      {parentCrop?.date_from &&
                        moment(parentCrop?.date_from).format('YYYY-MM-DD')}
                    </li>
                    <li>
                      <span className="text-muted">To:</span>{' '}
                      {parentCrop?.date_to &&
                        moment(parentCrop?.date_to).format('YYYY-MM-DD')}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="12" md="7">
                <Accordion>
                  <Accordion.Toggle
                    eventKey="0"
                    as="div"
                    style={styles.headerContainer}
                    onClick={onShowStatsPerDay}
                  >
                    <Card className="m-0">
                      <Card.Body>
                        More info (per day)
                        <FontAwesomeIcon icon={faPlus} style={styles.topPlus} />
                      </Card.Body>
                    </Card>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" style={styles.wrapper}>
                    <Card className="m-0 p-0">
                      <Card.Body className="m-0 p-0">
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={styles.topMinus}
                        />
                        {isLoadingPerDay && (
                          <Spinner size="sm" animation="border" role="status" />
                        )}
                        {errorPerDay && (
                          <span className="error-text text-nowrap">
                            Sorry, something went wrong
                          </span>
                        )}
                        {!isLoadingPerDay && !errorPerDay && (
                          <div style={styles.subContainer}>
                            <ul className="py-2 px-3 mt-0 mb-0 mr-0 ml-3">
                              {infoPerDay.map((el) => (
                                <li key={el.date}>
                                  {el.date}: {el.images_count} images,{' '}
                                  {el.crops_count} crops
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Accordion.Collapse>
                </Accordion>
              </Col>
            </Row>
            {isLoadingMistakes ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : (
              <Row>
                {errorMistakes ? (
                  <>
                    Flags:{' '}
                    <span className="error-text text-nowrap">
                      Sorry, something went wrong
                    </span>
                  </>
                ) : (
                  <>
                    <Col xs="12" md="5">
                      <div className="pt-2 mt-1">
                        <span className="text-muted">Flags:</span>
                        <ul>
                          <li>
                            <span className="text-muted">Wrong Both:</span>{' '}
                            {infoMistakes?.flags.WRONG_BOTH}
                          </li>
                          <li>
                            <span className="text-muted">
                              Wrong Prediction:
                            </span>{' '}
                            {infoMistakes?.flags.WRONG_PREDICTION}
                          </li>
                          <li>
                            <span className="text-muted">Wrong Tagged:</span>{' '}
                            {infoMistakes?.flags.WRONG_TAGGED}
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xs="12" md="7">
                      <Accordion>
                        <Accordion.Toggle
                          eventKey="1"
                          as="div"
                          style={styles.headerContainer}
                        >
                          <Card className="m-0">
                            <Card.Body>
                              More info (mistake types)
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={styles.topPlus}
                              />
                            </Card.Body>
                          </Card>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="1"
                          style={styles.wrapper2}
                        >
                          <Card className="m-0 p-0">
                            <Card.Body className="m-0 p-0">
                              <FontAwesomeIcon
                                icon={faMinus}
                                style={styles.topMinus}
                              />
                              <div style={styles.subContainer}>
                                <ul className="py-2 px-3 mt-0 mb-0 mr-0 ml-3">
                                  {infoMistakes?.mistake_types.map((el) => (
                                    <li key={el.label}>
                                      {el.label}: {el.value}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Card.Body>
                          </Card>
                        </Accordion.Collapse>
                      </Accordion>
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function ProductionReportDetails() {
  const [reportDetails, setReportDetails] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const [count, setCount] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [parentCrop, setParentCrop] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [parameters, setParameters] = useState({});
  const [error, setError] = useState(false);
  const [currentState, setCurrentState] = useState({});
  const [prevSelected, setPrevSelected] = useState({});

  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.crop) {
      setCurrentState(state);
      const newParentCrop = { ...state.crop };
      newParentCrop.accuracy = state.crop.accuracy
        ? `${(100 * state.crop.accuracy).toFixed(2)}%`
        : '';
      newParentCrop.created_at = moment(state.crop.created_at).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      newParentCrop.error_count = state.crop.error_count || '0';
      newParentCrop.errors_count_percent = state.crop.error_count
        ? `${(
            (100 * state.crop.crops_checked_count) /
            state.crop.error_count
          ).toFixed(2)}%`
        : '100%';
      newParentCrop.date_from = moment(state.crop.date_from).format(
        'YYYY-MM-DD'
      );
      newParentCrop.date_to = moment(state.crop.date_to).format('YYYY-MM-DD');

      const dateFrom = moment(newParentCrop.date_from);
      const dateTo = moment(newParentCrop.date_to);

      newParentCrop.days_duration = moment
        .duration(dateTo.diff(dateFrom))
        .asDays()
        .toFixed(0);
      setParentCrop(newParentCrop);
    }
  }, []);

  const roundScore = (score) => {
    const number = 10 ** 8;
    return Math.round(score * number) / number;
  };

  const setAuditReportDetails = (params) => {
    getAuditReportDetails(id, { ...params, page_size: PAGE_SIZE }).then(
      async (response) => {
        const status = await response.status;
        if (status === 200) {
          const thisState = state || currentState;
          await response
            .json()
            .then((data) => {
              const rowNames = [];
              data.results.forEach((item) => {
                rowNames.push({
                  Label: item.label,
                  'Sum Score': roundScore(item.score),
                  'Avg Score': roundScore(item.mean_score),
                  'Max Score': roundScore(item.max_score),
                  'Min Score': roundScore(item.min_score),
                  Check: (
                    <Link
                      to={{
                        pathname: `/check/${item.id}`,
                        state: {
                          label: item.label,
                          pathnameBack: `/audit-report-details/${thisState.crop.id}`,
                          stateBack: { ...thisState, ...params }
                        }
                      }}
                    >
                      <button
                        className="btn btn-light d-flex align-items-center"
                        type="button"
                      >
                        <span>
                          <span className="text-nowrap">
                            {item.crops_checked_count} (auto{' '}
                            {item.crops_auto_checked_count})
                          </span>
                          <span className="text-nowrap">
                            {' '}
                            / {item.crops_count}
                          </span>
                        </span>
                        <i className="nc-icon nc-stre-right" />
                      </button>
                    </Link>
                  )
                });
              });
              setReportDetails(rowNames);
              setPrevId(id);
              setNext(data.next);
              setCount(data.count);
              setPrevious(data.previous);
              setError(false);
            })
            .catch(() => setError(true));
        } else {
          setError(true);
        }
        setIsLoadingSearch(false);
      }
    );
  };

  useEffect(() => {
    if (prevId !== id) {
      const newPage = state?.page || 1;
      setPage(newPage);
      const params = { search: state?.search || '' };
      if (state?.ordering) params.ordering = state?.ordering;
      setParameters(params);
      setPrevSelected(params);
      setAuditReportDetails({ page: newPage, ...params });
    }
  }, [id]);

  const loadData = (params) => {
    setPage(params.page);
    setAuditReportDetails({ page: params.page, ...parameters });
  };

  const columnNames = [
    'Label',
    'Sum Score',
    'Avg Score',
    'Max Score',
    'Min Score',
    'Check'
  ];

  const filterCallback = (selectedSorting, searchLabel) => {
    setIsLoadingSearch(true);
    const params = { ...parameters, search: searchLabel };
    if (selectedSorting) params.ordering = selectedSorting;
    setPage(1);
    setParameters(params);
    setAuditReportDetails({ page: 1, ...params });
  };

  return (
    <>
      <InfoCard parentCrop={parentCrop} id={id} />
      <ProductionReportDetailFilters
        filterCallback={filterCallback}
        isLoadingSearch={isLoadingSearch}
        prevSelected={prevSelected}
      />
      {error ? (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      ) : (
        <Table
          page={page}
          next={next}
          count={count}
          previous={previous}
          rowNames={reportDetails}
          columnNames={columnNames}
          loadData={loadData}
        />
      )}
    </>
  );
}

export default ProductionReportDetails;
