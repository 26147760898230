import usePersistantState from './usePersistantState';

export enum CropSize {
  Small,
  Medium,
  Large,
}

export default function useCropSize(): [CropSize, (value: CropSize) => void] {
  return usePersistantState<CropSize>('CROP_SIZE', CropSize.Medium);
}
