import React, { useState, useEffect } from 'react';
import { Card, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ButtonGroupSorting from '../../components/ButtonCroup/buttonGroupSorting';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import {CUSTOM_INPUT_STYLES} from "../../components/StylesConstants";

function ProductionReportDetailFilters({ filterCallback, isLoadingSearch, prevSelected }) {
  const [selectedOrdering, setSelectedOrdering] = useState('');
  const [searchLabel, setSearchLabel] = useState('');

  const ordering = [
    { label: 'Sum score', valueAsc: 'score', valueDesc: '-score' },
    { label: 'Avg score', valueAsc: 'mean_score', valueDesc: '-mean_score' },
    { label: 'Max score', valueAsc: 'max_score', valueDesc: '-max_score' },
    { label: 'Min score', valueAsc: 'min_score', valueDesc: '-min_score' }
  ];

  useEffect(() => {
    if (prevSelected.ordering) setSelectedOrdering(prevSelected.ordering);
    if (prevSelected.search)  setSearchLabel(prevSelected.search);
  }, [prevSelected]);

  const onChangeOrdering = (el) => {
    setSelectedOrdering(el);
    if (el === selectedOrdering) setSelectedOrdering('');
  };

  const onClick = () => {
    filterCallback(selectedOrdering, searchLabel);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Additional Filters:</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mt-n3 pl-3 pb-2">
          {ordering.map((el) => (
            <div className="mt-3" key={el}>
              <ButtonGroupSorting
                sortingEl={el}
                onChangeSorting={onChangeOrdering}
                selectedSorting={selectedOrdering}
                isLoading={isLoadingSearch}
              />
            </div>
          ))}
          <div className="mr-3 mt-3">
            <Form.Control
              style={ CUSTOM_INPUT_STYLES }
              disabled={isLoadingSearch}
              type="search"
              onChange={(event) => setSearchLabel(event.target.value)}
              className="form-control"
              placeholder="Search Label"
              name="searchLabel"
              value={searchLabel}
            />
          </div>
          <div className="mt-3 mr-3">
            <SpinnerButton
              isLoading={isLoadingSearch}
              disabled={false}
              onClick={onClick}
              value="Search"
              style={{ minHeight: 50 }}
            />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}

ProductionReportDetailFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoadingSearch: PropTypes.bool
};

ProductionReportDetailFilters.defaultProps = {
  filterCallback: () => {},
  isLoadingSearch: false
};

export default ProductionReportDetailFilters;
