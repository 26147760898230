export const fetchImagesQueued = (
  crops,
  apiCall,
  onImageLoaded,
  onImageLoadFailed,
  getImgUrl
) => {
  const GROUP_SIZE = 10;
  let countRequests = 0;
  let countResponses = 0;
  let stopLoading = false;

  const cancelLoad = function () {
    stopLoading = true;
  };

  const tryLoadImg = (source) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      const url = source;
      img.onload = function () {
        resolve();
      };
      img.onerror = function () {
        reject();
      };
      img.src = url;
    });
  };

  const loadImg = async (source, operation = tryLoadImg, retries = 2) => {
    const wait = (ms) =>
      new Promise((resolve) => {
        setTimeout(() => resolve(), ms);
      });
    const delay = 500;

    return new Promise((resolve, reject) => {
      operation(source)
        .then(resolve)
        .catch(() => {
          if (retries > 0) {
            return wait(delay)
              .then(loadImg.bind(null, source, operation, retries - 1))
              .then(resolve)
              .catch(reject);
          }
          return reject();
        });
    });
  };

  return {
    result: new Promise((resolve) => {
      const requestCropInfo = () => {
        if (countResponses === crops.length || stopLoading) {
          resolve();
        } else if (countRequests < crops.length) {
          const cropInfo = crops[countRequests];
          countRequests += 1;
          apiCall(cropInfo)
            .then(async (response) => {
              const status = await response.status;
              if (status === 200) {
                const data = await response.json();
                await loadImg(getImgUrl(data))
                  .then(() => {
                    if (!stopLoading) onImageLoaded(data, cropInfo);
                  })
                  .catch(() => {
                    if (!stopLoading) onImageLoadFailed(cropInfo);
                  });
              } else {
                onImageLoadFailed(cropInfo);
              }
            })
            .finally(() => {
              countResponses += 1;
              requestCropInfo();
            });
        }
      };

      for (let i = 0; i < Math.min(GROUP_SIZE, crops.length); i += 1) {
        requestCropInfo();
      }
    }),
    cancelLoad
  };
};

export const fetchQueued = (crops, apiCall, onSuccess, onError) => {
  const GROUP_SIZE = 20;
  let countRequests = 0;
  let countResponses = 0;
  let stopLoading = false;

  const cancelLoad = function () {
    stopLoading = true;
  };

  return {
    result: new Promise((resolve) => {
      const requestCropInfo = () => {
        if (countResponses === crops.length || stopLoading) {
          resolve();
        } else if (countRequests < crops.length) {
          const cropInfo = crops[countRequests];
          countRequests += 1;
          apiCall(cropInfo)
            .then(async (response) => {
              const status = await response.status;
              if (status === 200) {
                const data = await response.json();
                onSuccess(cropInfo, data);
              } else {
                onError(cropInfo);
              }
            })
            .finally(() => {
              countResponses += 1;
              requestCropInfo();
            });
        }
      };

      for (let i = 0; i < Math.min(GROUP_SIZE, crops.length); i += 1) {
        requestCropInfo();
      }
    }),
    cancelLoad
  };
};
