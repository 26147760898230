import React, { useState, useEffect } from 'react';
import { Card, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CUSTOM_INPUT_STYLES } from '../../components/StylesConstants';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import ButtonGroupSorting from '../../components/ButtonCroup/buttonGroupSorting';
import SelectJobType from '../../components/Select/SelectJobType';
import SelectState from '../../components/Select/SelectState';
import SelectSubState from '../../components/Select/SelectSubState';
import SelectStation from '../../components/Select/SelectStation';
import Select from '../../components/Select/Select';
import { getLabelers } from '../../api/user';
import SelectAction from '../../components/Select/SelectAction';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';

function StatisticsFilters({ filterCallback, isLoading }) {
  const [labelers, setLabelers] = useState([{}]);
  const [reviewers, setReviewers] = useState([{}]);
  const [clusterId, setClusterId] = useState('');
  const [selectedUserTypeFilter, setSelectedUserTypeFilter] = useState({
    label: 'All data',
    value: 'all'
  });
  const [selectedSortOn, setSelectedSortOn] = useState('-updated_at');
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedSubState, setSelectedSubState] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedLabeler, setSelectedLabeler] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    getLabelers().then(async (response) => {
      const data = await response.json();
      const allLabelers = data.results;
      const labelers = [];
      const reviewers = [];
      allLabelers.forEach((labeler) => {
        const option = { value: labeler.id, label: `${labeler.user.username}-${labeler.id}` };
        labelers.push(option);
        if (labeler.permission === 'QC') reviewers.push(option);
        if (labeler.permission === 'STAFF') reviewers.push(option);
      });
      setLabelers(labelers);
      setReviewers(reviewers);
    });
  }, []);

  const userTypeFilter = [
    { label: 'Labeler only', value: 'LABELER' },
    { label: 'Reviewer only', value: 'REVIEWER' },
    { label: 'All data', value: 'all' }
  ];
  const sortOn = [
    { label: 'Updated at', valueAsc: '-updated_at', valueDesc: 'updated_at' },
    { label: 'Time for action', valueAsc: 'time_for_action', valueDesc: '-time_for_action' },
    { label: 'Total time for action', valueAsc: 'total_time_for_action', valueDesc: '-total_time_for_action' },
    { label: 'Total Time', valueAsc: 'total_time', valueDesc: '-total_time' }
  ];

  const onSortOnClick = (el) => {
    const newSortOn = el === selectedSortOn ? null : el;
    setSelectedSortOn(newSortOn);
  };
  const setDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onClick = () => {
    filterCallback({
      clusterId,
      selectedUserTypeFilter,
      selectedSortOn,
      selectedJobType,
      selectedState,
      selectedSubState,
      selectedStation,
      selectedLabeler,
      selectedReviewer,
      selectedAction,
      startDate,
      endDate
    });
  };
  const isDisabled = isLoading || !selectedJobType?.label;

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">Filters:</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mt-n3 pl-3">
          <SelectJobType
            selectedJobType={selectedJobType}
            setSelectedJobType={setSelectedJobType}
            isDisabled={isLoading}
            className="mr-3 mt-3"
            isClustering
          />
          <SelectState
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <SelectSubState
            selectedSubState={selectedSubState}
            setSelectedSubState={setSelectedSubState}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <SelectStation
            selectedStation={selectedStation}
            setSelectedStation={setSelectedStation}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <Select
            value={selectedLabeler}
            onChange={setSelectedLabeler}
            options={labelers}
            isDisabled={isLoading}
            placeholder="Select Labeler"
            isSearchable
            className="mr-3 mt-3"
            isClearable
          />
          <Select
            value={selectedReviewer}
            onChange={setSelectedReviewer}
            options={reviewers}
            isDisabled={isLoading}
            placeholder="Select Reviewer"
            isSearchable
            className="mr-3 mt-3"
            isClearable
          />
          <SelectAction
            setSelectedAction={setSelectedAction}
            selectedAction={selectedAction}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <div className="mr-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoading}
              type="search"
              onChange={(event) => setClusterId(event.target.value)}
              className="form-control"
              placeholder="Search on C. ID"
              name="searchClusterId"
              value={clusterId}
            />
          </div>
          <Select
            value={selectedUserTypeFilter}
            onChange={setSelectedUserTypeFilter}
            options={userTypeFilter}
            isDisabled={isLoading}
            placeholder=""
            className="mr-3 mt-3"
          />
          <div className=" d-flex flex-wrap flex-md-nowrap">
            {sortOn.map((el) => (
              <div className="mr-3 mt-3" key={el.label}>
                <ButtonGroupSorting
                  sortingEl={el}
                  onChangeSorting={onSortOnClick}
                  selectedSorting={selectedSortOn}
                  isLoading={isLoading}
                />
              </div>
            ))}
          </div>
          <div className="mt-3">
            <DateRangePicker setDate={setDate} isLoading={isLoading} />
          </div>
          <div className="mr-3 mt-3">
            <SpinnerButton
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="Search"
              style={{ height: '50px' }}
            />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}

StatisticsFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool
};

StatisticsFilters.defaultProps = {
  filterCallback: () => {},
  isLoading: false
};

export default StatisticsFilters;
