import { Cookies } from 'react-cookie';

export const userIsLogged = () => {
  const cookies = new Cookies();
  return cookies.get('user') !== undefined;
};

export const getUserToken = () => {
  const user = new Cookies().get('user');
  if (user) {
    const { token } = user;
    return token;
  }
  window.location.pathname = '/login';
};

export const percentage = (partialValue, totalValue) => (100 * partialValue) / totalValue;

export const getRectagle = (labelId, photoObjects) => {
  const result = photoObjects
    .filter((item) => item.description.properties.featurestore_id === labelId)
    .shift();
  return result ? result.rectangle : {};
};

let curId = 1;
// eslint-disable-next-line no-plusplus
export const nextId = () => curId++;

export const toPlural = (count, name) => count === 1 ? name : `${name}s`;

export const cropsPlural = (cropsCount) => toPlural(cropsCount, 'crop');
