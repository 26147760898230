// TODO: Fixme
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  CloseButton,
  OverlayTrigger,
  Popover,
  PopoverTitle,
} from 'react-bootstrap';
import IconLinkButton from 'components/Buttons/IconLinkButton';
import { CheckIcon2 } from 'components/Icons';
import { Link } from 'react-router-dom';
import ConfirmModal from 'modals/ConfirmModal';
import { cropsPlural } from 'utils';
import { Cluster } from './Cleaning';

const MoveToClusterButton: FC<{
  clusters: Cluster[];
  selectedClusterId: string;
  onMoveToNewCluster: (name: string) => void;
  onMoveToCluster: (clusterId: string) => void;
}> = ({ clusters, selectedClusterId, onMoveToNewCluster, onMoveToCluster }) => {
  const [clustersPopoverVisible, setClustersPopoverVisible] = useState(false);
  const [prevClusters, setPrevClusters] = useState<any>();

  const [newClusterName, setNewClusterName] = useState('');

  const targetClusters = clusters.filter(({ id }) => id !== selectedClusterId);
  const title =
    targetClusters.length > 0
      ? 'Move to another cluster'
      : 'Move to new cluster';

  if (clusters !== prevClusters) {
    setPrevClusters(clusters);
    setNewClusterName(`Draft #${targetClusters.length + 1}`);
  }
  const handlePopoverToggle = () => {
    setClustersPopoverVisible((prev) => !prev);
  };

  const handeAddNewCluster = () => {
    setClustersPopoverVisible(false);
    onMoveToNewCluster(newClusterName);
  };

  const nameNewCluster = (
    <div>
      <label htmlFor="newClasterName" className="pb-2 mb-1 d-block">
        Name new cluster (optional)
      </label>
      <div className="text-nowrap">
        <input
          id="newClusterName"
          type="text"
          defaultValue={newClusterName}
          onChange={(e) => setNewClusterName(e.target.value)}
        />
        <Button className="p-1 d-inline-block" variant="outline-secondary">
          <CheckIcon2 onClick={handeAddNewCluster} />
        </Button>
      </div>
    </div>
  );

  const addNewClusterOverlay = (
    <Popover id="addNewClusterPopover">
      <Popover.Content>{nameNewCluster}</Popover.Content>
    </Popover>
  );

  const moveToClusterOverlay = (
    <Popover id="moveToClusterPopover">
      <PopoverTitle>
        <CloseButton onClick={handlePopoverToggle} />
        {title}&nbsp;
      </PopoverTitle>
      <Popover.Content>
        {!targetClusters.length ? (
          nameNewCluster
        ) : (
          // TODO: rename class?
          <div className="linksList">
            {targetClusters.map(({ id, name }) => (
              <Link key={id} to="#" onClick={() => onMoveToCluster(id)}>
                {name}
              </Link>
            ))}
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={addNewClusterOverlay}>
              <Link to="#" key="addNewCluster">
                Add new cluster
              </Link>
            </OverlayTrigger>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={moveToClusterOverlay}
      show={clustersPopoverVisible}
      onToggle={handlePopoverToggle}>
      <IconLinkButton icon="newClaster" className="menu-action">
        {title}
      </IconLinkButton>
    </OverlayTrigger>
  );
};

export interface CropActionsMenuProps {
  contentDiv?: HTMLDivElement | null;
  cropsCount: number;
  clusters: Cluster[];
  selectedClusterId: string;
  disableCropsMoving?: boolean;
  onMoveToNewCluster: (name: string) => void;
  onMoveToCluster: (clusterId: string) => void;
  onRemoveCrops: () => void;
  onDeselectAll: () => void;
}

export const CropActionsMenu = (
  props: CropActionsMenuProps,
): JSX.Element | null => {
  const {
    cropsCount,
    clusters,
    selectedClusterId,
    disableCropsMoving,
    onMoveToNewCluster,
    onMoveToCluster,
    onRemoveCrops,
    onDeselectAll,
  } = props;

  const [showRemoveCropsModal, setShowRemoveCropsModal] = useState(false);

  const cropsLabel = cropsPlural(cropsCount);

  if (!cropsCount) return null;
  const container = document.querySelector('.wrapper');
  if (!container) return null;
  return createPortal(
    <>
      <div className="new-ui actions-menu-wrapper">
        <div className="menu">
          <div className="menu-info">
            {cropsCount}&nbsp;
            {cropsLabel}
            &nbsp; selected:
          </div>

          <div className="menu-line">
            {!disableCropsMoving && (
              <>
                <IconLinkButton
                  icon="trashW"
                  className="menu-action"
                  onClick={() => setShowRemoveCropsModal(true)}>
                  Remove Crops
                </IconLinkButton>
                <MoveToClusterButton
                  clusters={clusters}
                  selectedClusterId={selectedClusterId}
                  onMoveToNewCluster={onMoveToNewCluster}
                  onMoveToCluster={onMoveToCluster}
                />
              </>
            )}
          </div>
          <div>
            <CloseButton
              className="menu-action menu-close"
              onClick={() => onDeselectAll()}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        header="Remove Crops"
        confrimLabel="Remove Crops"
        cancelLablel="Cancel"
        show={showRemoveCropsModal}
        onCancel={() => setShowRemoveCropsModal(false)}
        onConfirm={() => {
          setShowRemoveCropsModal(false);
          onRemoveCrops();
        }}>
        You&apos;re about to delete <b>{cropsCount}</b> {cropsLabel} from the
        cluster. Once deleted, this action cannot be reversed.
      </ConfirmModal>
    </>,
    container,
  );
};

export default CropActionsMenu;
