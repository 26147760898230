import { getClusterHistory } from 'api/clusters';
import IconLinkButton from 'components/Buttons/IconLinkButton';
import ModalWithHistory from 'components/Modal/ModalWithHistory';
import React, { FC, useState } from 'react';

const HistoryButton: FC<{ clusterId?: string }> = ({ clusterId }) => {
  const [showModal, setShowModal] = useState(false);
  const [clusterHistory, setClusterHistory] = useState<any>();

  const openModal = () => {
    setShowModal(true);
    setClusterHistory(null);
    getClusterHistory(clusterId).then(async (response: any) => {
      const data = await response.json();
      setClusterHistory(data);
    });
  };
  return (
    <>
      <IconLinkButton
        icon="info"
        className="mr-3"
        onClick={openModal}
        disabled={!clusterId}>
        History
      </IconLinkButton>
      <ModalWithHistory
        handleClose={() => setShowModal(false)}
        show={showModal}
        data={clusterHistory}
      />
    </>
  );
};
HistoryButton.displayName = 'HistoryButton';

export default HistoryButton;
