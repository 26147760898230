import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

function ArrayDropdown({
  items,
  list,
  onClick,
  disabled,
  defaultValue,
  openDropdown,
  newUI,
}) {
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (items.length) {
      const options = items.reduce((acc, currItem) => {
        acc.push({ label: currItem, value: currItem });
        return acc;
      }, []);
      setOptions(options);
    }
  }, [items]);

  const customStylesUi = newUI ?  // TODO: read `new-ui` class to see if we are in new UI?
    {
      width: 342,
      border: 'none',
    } :
    {
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50,
    }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      ...customStylesUi,
      zIndex: 0,
    }),
    option: (provided, { data, selectProps }) => ({
      ...provided,
      color:
        data.rejected && data.rejected.includes(selectProps.rejected)
          ? '#55AB94'
          : '',
    }),
    // display: 'inline-block' // TODO: Make an option
  };

  const onChange = (selectedOption) => {
    setSelectedJobType(selectedOption);
    const value = selectedOption ? selectedOption.value : '';
    onClick(value);
  };

  return (
    <Select
      value={selectedJobType}
      styles={customStyles}
      onChange={onChange}
      options={list || options}
      placeholder={defaultValue}
      isSearchable
      isClearable
      isDisabled={!!disabled}
      required
      className={classNames({ "mr-3 ml-3": !newUI })}
      onMenuOpen={openDropdown}
    />
  );
}

ArrayDropdown.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  newUI: PropTypes.bool,
};

ArrayDropdown.defaultProps = {
  items: [],
  onClick: () => { },
  disabled: false,
  defaultValue: 'Please Select',
  newUI: false,
};

export default ArrayDropdown;
