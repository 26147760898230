// TODO: Add key events
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckIcon,
  CheckSelectedIcon,
  GoBackIcon,
  NextIcon,
  PrevIcon,
  ShelfIcon,
} from 'components/Icons';
import React, { FC, UIEvent, useEffect, useState } from 'react';
import { Point } from './Cleaning';

const CropView: FC<{
  currentCropId?: string;
  crops: Point[];
  selectedCrops: { [key: string]: any };
  onCloseCropView: () => void;
  onSelectCrop: (cropId: string) => void;
  onShowShelfView: (cropId: string) => void;
}> = ({
  currentCropId,
  crops,
  selectedCrops,
  onCloseCropView,
  onSelectCrop,
  onShowShelfView,
}) => {
  const [currentCrop, setCurrentCrop] = useState<Point | undefined>();
  useEffect(() => {
    setCurrentCrop(crops?.find(({ id }) => id === currentCropId));
  }, [crops, currentCropId]);

  const navigateCrop = (e: UIEvent, next: boolean) => {
    e.stopPropagation();
    if (!currentCrop) return;
    const index =
      (crops.indexOf(currentCrop) + (next ? 1 : -1) + crops.length) %
      crops.length;
    setCurrentCrop(crops[index]);
  };

  const selected = currentCrop && selectedCrops[currentCrop.id];

  if (!currentCropId) return null;
  return (
    // TODO: Add hover state to buttons
    <div className="crop-view">
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={currentCrop?.crop_path}
        alt="crop-preview"
      />
      <div
        className="crop-view-button button-prev"
        title="Previous crop"
        onClick={(e) => navigateCrop(e, false)}>
        <PrevIcon />
      </div>
      <div
        className="crop-view-button button-next"
        title="Next crop"
        onClick={(e) => navigateCrop(e, true)}>
        <NextIcon />
      </div>
      <div
        className="crop-view-button button-back"
        title="Close"
        onClick={(e) => {
          e.stopPropagation();
          onCloseCropView();
        }}>
        <GoBackIcon />
      </div>
      <div
        className="crop-view-button button-select"
        title={selected ? 'Selected' : 'Not selected'}
        onClick={(e) => {
          e.stopPropagation();
          if (currentCrop) onSelectCrop(currentCrop.id);
        }}>
        {selected ? <CheckSelectedIcon /> : <CheckIcon />}
      </div>
      <div
        className="crop-view-button button-shelf-view"
        title="View shelf"
        onClick={(e) => {
          e.stopPropagation();
          if (currentCrop) onShowShelfView(currentCrop.id);
        }}>
        <ShelfIcon />
      </div>
    </div>
  );
};
CropView.displayName = 'CropView';

export default CropView;
