import React, { useState, useEffect } from 'react';
import { Accordion, Alert, Col, Row, Spinner } from 'react-bootstrap';
import { getClustersRejections } from 'api/clusters';
import moment from 'moment';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorIcon } from '../Icons';

function ButtonSpinner() {
  return <Spinner size="md" animation="border" role="status" />;
}

// This is new RejectErrorComponent for new UI

function RejectInfoComponent({ id }) {
  const [clustersRejectionsHistory, setClustersRejectionsHistory] = useState(
    {},
  );
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastComment, setLastComment] = useState('');

  useEffect(() => {
    getClustersRejections(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setClustersRejectionsHistory(data.results);
        const lastEl = data.results[0];
        let lastComment;
        if (!lastEl) {
          setIsError(true);
        } else if (lastEl.comment && lastEl.comment.length > 0) {
          lastComment = lastEl.comment;
        } else {
          lastComment = 'last reject without comment';
        }
        setLastComment(lastComment);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      {isLoading && (
        <Alert variant="danger">
          <ButtonSpinner />
        </Alert>
      )}
      {!isLoading && (
        <Alert
          variant="danger"
          style={{
            paddingLeft: 44,
            maxHeight: 116,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {isError ? (
            <>This cluster is rejected, but we don't have any additional info</>
          ) : (
            <div>
              {clustersRejectionsHistory.map((el, index) => (
                <Row className={!!index && 'pt-3'}>
                  <Col xs={12} md={7}>
                    <span className="fw-500">
                      {index === 0 ? (
                        <>
                          <span
                            style={{
                              position: 'absolute',
                              margin: '-2px 0 0 -32px',
                            }}
                          >
                            <ErrorIcon />
                          </span>
                          Rejected cluster:
                        </>
                      ) : (
                        <>
                          <span className="bull-red" />
                          <span className="text-black-75">
                            Reject {clustersRejectionsHistory.length - index}:
                          </span>
                        </>
                      )}
                    </span>
                    {' '}
                    <span className={!!index && 'text-black-75'}>
                      {el.comment ? el.comment : 'this reject without comment'}
                    </span>
                  </Col>
                  <Col xs={12} md={5} className="text-right">
                    <span className="text-nowrap text-black-75">
                      Rejected by reviewer ID:
                      {el.reviewer_id ? el.reviewer_id : 'unknown'}
                    </span>
                    <span className="text-nowrap text-black-75">
                      {'  '}at{' '}
                      {el.created
                        ? moment(el.created).format('YYYY-MM-DD HH:mm')
                        : 'unknown'}
                    </span>
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </Alert>
      )}
    </>
  );
}

export default RejectInfoComponent;
