import { ClusterDataTO } from './api.types';
import {
  acceptCluster,
  assignUser,
  moveToNewCluster,
  rejectCluster,
  rejectClusterWithComment,
  removeCluster,
  removeSelectedCrops,
  sendCluster,
} from './clusters';
import { MAIN_CLUSTER_ID, Point } from '../views/Clusters/Cleaning/Cleaning';

const splitStatusData = async <T,>(response: Response) => {
  const { status } = response;
  const data: T = await response.json();
  return { data, status };
};

export const assignCluster = async (
  labeler: boolean,
  predefinedClasses: string,
): Promise<{ data: ClusterDataTO; status: number }> => {
  const userType = labeler ? 'labeler' : 'reviewer';
  const checkSubState = userType === 'reviewer' ? 'CLEANING_QC' : 'CLEANING';
  const response = await assignUser(userType, {
    predefined_classes: predefinedClasses,
    substate: checkSubState,
  });
  return splitStatusData<ClusterDataTO>(response);
};

const moveMultipleClusters = async (
  id: string,
  params: {
    fix?: boolean;
    comment?: string;
    cropIds?: string[];
    clusterCrops: { [k: string]: Point[] };
  },
) => {
  return Promise.allSettled(
    Object.entries(params.clusterCrops)
      .filter(([clusterId]) => clusterId !== MAIN_CLUSTER_ID)
      .map(([_, crops]) =>
        moveToNewCluster(id, params?.fix ? { fix: true } : null, {
          crop_ids: crops.map((crop) => crop.id),
        }),
      ),
  ).then((responses) => {
    if (responses.find((r) => r.status === 'rejected'))
      return { json: () => Promise.reject('rejected') };
    const response: any = responses[0]; // TODO: fix typecasting 'as' in eslint
    return response.value;
  });
};

export type ClusterAction =
  | 'send'
  | 'reject'
  | 'rejectClusterWithComment'
  | 'removeCluster'
  | 'removeSelectedCrops'
  | 'accept'
  | 'acceptAllNewClusters';

// TODO: type function return
export const getClusterAction = async (
  action: ClusterAction,
  id: string,
  params: {
    fix?: boolean;
    comment?: string;
    cropIds?: string[];
    clusterCrops: { [k: string]: Point[] };
  },
): Promise<any> => {
  switch (action) {
    case 'send':
      return sendCluster(id);
    case 'accept':
      return acceptCluster(id);
    case 'reject':
      return rejectCluster(id);
    case 'rejectClusterWithComment':
      return rejectClusterWithComment(id, { comment: params?.comment });
    case 'removeCluster':
      return removeCluster(id, params?.fix ? { fix: true } : undefined);
    case 'removeSelectedCrops':
      return removeSelectedCrops(id, params?.fix ? { fix: true } : undefined, {
        crop_ids: params?.cropIds,
      });
    case 'acceptAllNewClusters':
      return moveMultipleClusters(id, params);
  }
};
