import React, { Children, FC } from 'react';
import { Nav } from 'react-bootstrap';

interface TabViewItemProps {
  tabKey: string | number;
  label: string;
}
export const TabViewItem: FC<TabViewItemProps> = ({ children }) => {
  return <div className="tab"> {children}</div>;
};

interface TabViewProps {
  activeKey: string;
  onSelect?: (key: string | null) => void;
}
const TabView: FC<TabViewProps> = ({ activeKey, children, onSelect }) => {
  return (
    <div className="sub-body">
      <Nav
        style={{ width: '100%' }}
        activeKey={activeKey}
        onSelect={(key) => onSelect && onSelect(key)}>
        {Children.map(children, (child: any) => (
          <Nav.Item>
            <Nav.Link eventKey={child.props?.tabKey}>
              {child.props?.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {Children.map(children, (child: any) => (
        <div
          className="tabview-tab"
          style={{
            display: child.props?.tabKey === activeKey ? undefined : 'none',
          }}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default TabView;
