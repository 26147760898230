import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

function DialogModal({
  title,
  body,
  back,
  approve,
  show,
  handleClose,
  handleApprove,
  size,
  disabled
}) {
  return (
    <Modal size={size || 'sm'} show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {back && (
          <Button variant="primary" onClick={handleClose}>
            {back}
          </Button>
        )}
        {approve && (
          <Button disabled={disabled} variant="danger" onClick={handleApprove}>
            {approve}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

DialogModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  back: PropTypes.string,
  approve: PropTypes.string,
  show: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleClose: PropTypes.func
};

DialogModal.defaultProps = {
  title: '',
  body: '',
  back: '',
  approve: '',
  show: false,
  handleApprove: () => {},
  handleClose: () => {}
};

export default DialogModal;
