import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getBBox } from 'api/clusters';
import ModalWithSVGShape from 'components/Modal/ModalWithSVGShape';

// TODO: Copied from old UI, more love and TS needed here
const CropShelfView: FC<{ cropId: string | null; onClose: () => void }> = ({
  cropId,
  onClose,
}) => {
  const [showSVGShape, setShowSVGShape] = useState(false);
  const [dimensions, setDimensions] = useState({});
  const [showModalError, setShowModalError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (cropId) {
      getBBox(cropId).then(async (response: any) => {
        const status = await response.status;
        if (status === 200) {
          const data = await response.json();
          const result = {
            ...data,
            url: data.source_img_url,
          };
          setDimensions(result);
          setShowSVGShape(true);
        } else if (status === 204) {
          setErrorText(
            'For this crop there is no original job image available.',
          );
          setShowModalError(true);
        } else {
          setErrorText('Sorry, something went wrong');
          setShowModalError(true);
        }
      });
    } else {
      setShowSVGShape(false);
      setShowModalError(false);
    }
  }, [cropId]);

  if (!cropId) return null;
  return (
    <>
      <ModalWithSVGShape
        crop={dimensions}
        handleClose={() => {
          setShowSVGShape(false);
          onClose();
        }}
        show={showSVGShape}
      />
      <Modal
        show={showModalError}
        onHide={() => {
          setShowModalError(false);
          onClose();
        }}>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>{errorText}</Modal.Body>
      </Modal>
    </>
  );
};

export default CropShelfView;
