import React, { ReactElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrayDropdown from './ArrayDropdown';

type JobTypeDropdownProps = {
  jobTypeList: any[];
  onClickJobType: (source: any) => void;
  disabled?: boolean;
  openDropdown?: () => void;
  predefinedClass?: boolean;
  newUI?: boolean;
};

function JobTypeDropdown({
  jobTypeList,
  onClickJobType,
  disabled,
  openDropdown,
  predefinedClass,
  newUI,
}: JobTypeDropdownProps): ReactElement<any, any> {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!jobTypeList.length) return;
    const updatedOptions = jobTypeList.reduce((acc, currItem) => {
      const value =
        currItem[`${predefinedClass ? 'predefined_classes_id' : 'id'}`];
      acc.push({
        label: `${currItem.name}-${value}`,
        value: currItem,
        rejected: !!currItem.rejected_count,
      });
      return acc;
    }, []);
    setOptions(updatedOptions);
  }, [jobTypeList, predefinedClass]);

  return (
    <ArrayDropdown
      onClick={(source) => onClickJobType(source)}
      defaultValue="Select Job Type"
      openDropdown={openDropdown}
      disabled={disabled}
      list={options}
      newUI={newUI}
    />
  );
}

JobTypeDropdown.propTypes = {
  jobTypeList: PropTypes.array,
  onClickJobType: PropTypes.func,
  // selectedJobType: PropTypes.object,
  // withClear: PropTypes.bool,
  disabled: PropTypes.bool,
  predefinedClass: PropTypes.bool,
  newUI: PropTypes.bool,
};

JobTypeDropdown.defaultProps = {
  jobTypeList: [],
  onClickJobType: () => {},
  // selectedJobType: undefined,
  // withClear: false,
  disabled: false,
  predefinedClass: false,
  newUI: false,
  openDropdown: null,
};

export default JobTypeDropdown;
